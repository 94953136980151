import React, { useReducer, useContext, useLayoutEffect } from "react";
import { AuthContext } from "../../Layouts";

// libs import
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/material";
import moment from "moment";

// components import
import Charts from "../../Components/Charts";
import { GeneralCard } from "../../Components/Card";
import { getTaskProgress } from "../../Services/Periksa";
import Barlow from "../../Components/Typography/Barlow";
import ChartComponent from "./ChartComponent";

// static variable
const reducer = (state, action) => {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: action.value,
      };

    case "setCharts":
      return {
        ...state,
        data: { ...action.payload },
      };

    default:
      break;
  }
};

const ACTION = {
  loading: "loading",
  setCharts: "setCharts",
};

const Dashboard = ({ userId }) => {
  const { currentUser } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, {
    data: {
      resultImt: [],
      resultAdl: [],
      resultIadl: [],
      resultNyeri: [],
      resultResiko: [],
      resultKerentaan: [],
      resultPsikologis: [],
      resultKognitif: [],
      resultDukungan: [],
      resultTandaVital: [],
    },
    loading: false,
  });

  useLayoutEffect(() => {
    getTaskProgress(userId ? userId : currentUser?.userId, (e) =>
      dispatch({ type: ACTION.loading, value: e })
    ).then((res) => {
      if (res?.status !== 400) {
        console.log(res.data());

        // set data charts
        dispatch({ type: ACTION.setCharts, payload: res.data() });
      }
    });
  }, []);
  return (
    <Stack spacing={1}>
      {/* tanda vital */}
      <Barlow variant="h3">Tanda Vital</Barlow>
      <Grid container spacing={2}>
        <ChartComponent
          title="Result Tanda Vital"
          content={state.data.resultTandaVital}
          contentKey={["sistolik", "diastolik", "nadi", "respirasi", "suhu"]}
          color={["#34f89a", "#ea8650", "#a30078", "#ff4b63", "#0856be"]}
          md={12}
        />
      </Grid>

      {/* fungsi fisik */}
      <Barlow variant="h3">Fungsi Fisik</Barlow>

      {/* first line grid */}
      <Grid container spacing={2}>
        {/* charts for imt */}
        <ChartComponent title="Result IMT" content={state.data.resultImt} />

        {/* charts for adl */}
        <ChartComponent title="Result ADL" content={state.data.resultAdl} />
      </Grid>

      {/* second line grid */}
      <Grid container spacing={2}>
        {/* charts for iadl */}
        <ChartComponent title="Result IADL" content={state.data.resultIadl} />

        {/* charts for nyeri */}
        <ChartComponent title="Result Nyeri" content={state.data.resultNyeri} />
      </Grid>

      {/* third line grid */}
      <Grid container spacing={2}>
        {/* charts for resiko jatuh */}
        <ChartComponent
          title="Result Resiko Jatuh"
          content={state.data.resultResiko}
        />

        {/* charts for status kerentaan */}
        <ChartComponent
          title="Result Status Kerentanan"
          content={state.data.resultKerentaan}
        />
      </Grid>

      {/* status psikologis */}
      <Barlow variant="h3">Status Psikologis</Barlow>
      <Grid container spacing={2}>
        <ChartComponent
          title="Result Status Psikologis"
          content={state.data.resultPsikologis}
          md={12}
        />
      </Grid>

      {/* fungsi kognitif  */}
      <Barlow variant="h3">Fungsi Kognitif</Barlow>
      <Grid container spacing={2}>
        <ChartComponent
          title="Result Fungsi Kognitif"
          content={state.data.resultKognitif}
          md={12}
        />
      </Grid>

      {/* status dukungan sosial */}
      <Barlow variant="h3">Status Dukungan Sosial</Barlow>
      <Grid container spacing={2}>
        <ChartComponent
          title="Result Dukungan Sosial"
          content={state.data.resultDukungan}
          md={12}
        />
      </Grid>
    </Stack>
  );
};

export default Dashboard;
