export const DukunganSosialInput = {
  ask1: {
    label:
      "Saya dapat mengandalkan keluarga untuk membantu saya apabila saya mengalami kesulitan",
    answers: [
      {
        label: "Selalu",
        value: 2,
      },
      {
        label: "Kadang-kadang",
        value: 1,
      },
      {
        label: "Hampir tidak pernah",
        value: 0,
      },
    ],
  },
  ask2: {
    label:
      "Saya puas keluarga melibatkan saya berdiskusi dalam menyelesaikan masalah atau perbincangan harian",
    answers: [
      {
        label: "Selalu",
        value: 2,
      },
      {
        label: "Kadang-kadang",
        value: 1,
      },
      {
        label: "Hampir tidak pernah",
        value: 0,
      },
    ],
  },
  ask3: {
    label:
      "Keluarga menerima dan mendukung keinginan saya untuk melakukan aktivitas atau kegiatan baru",
    answers: [
      {
        label: "Selalu",
        value: 2,
      },
      {
        label: "Kadang-kadang",
        value: 1,
      },
      {
        label: "Hampir tidak pernah",
        value: 0,
      },
    ],
  },
  ask4: {
    label:
      "Saya puas terhadap cara keluarga mengungkapkan afeknya dan memberikan respon terhadap emosi saya. Saya merasakan kasih sayang keluarga",
    answers: [
      {
        label: "Selalu",
        value: 2,
      },
      {
        label: "Kadang-kadang",
        value: 1,
      },
      {
        label: "Hampir tidak pernah",
        value: 0,
      },
    ],
  },
  ask5: {
    label: "Keluarga menyediakan cukup waktu untuk bersama-sama saya",
    answers: [
      {
        label: "Selalu",
        value: 2,
      },
      {
        label: "Kadang-kadang",
        value: 1,
      },
      {
        label: "Hampir tidak pernah",
        value: 0,
      },
    ],
  },
};
