import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {Divider} from "@mui/material";

const MuiRadio = ({label, value, onChange, options, optionStyle}) => {
  return (
    <>
      <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
      <RadioGroup name={label} value={value} onChange={onChange}>
        {options.map((option, index) => (
          <div key={index}>
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
              sx={{...optionStyle, paddingBottom: 1, paddingTop: 1}}
            />
            <Divider />
          </div>
        ))}
      </RadioGroup>
    </>
  );
};

export default MuiRadio;
