import React from "react";
import {Box} from "@mui/material";
import {RotateRightRounded} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

const Spinner = styled(RotateRightRounded)(({theme}) => ({
  animation: `nfLoaderSpin infinite 700ms linear`,
  transformBox: "fill-box",

  "@keyframes nfLoaderSpin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

const LoadingState = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner sx={{width: 200, height: 200}} color="primary" />
      </Box>
    </>
  );
};

export default LoadingState;
