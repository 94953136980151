import React, {useContext, useReducer} from "react";
import {AuthContext} from "../../../../Layouts";
import {FungsiFisikContext} from "..";

// libs import
import {Box, Button, FormControl} from "@mui/material";
import {SaveRounded} from "@mui/icons-material";
import {arrayUnion} from "firebase/firestore";

// components import
import MuiRadio from "../../../../Components/RadioButton";
import {updateTaskProgress} from "../../../../Services/Periksa";

// static constant
import {IadlInput} from "../../../../Helper/IadlInput";
import {
  radioErrorCheck,
  radioMessageTemplate,
} from "../../../../Services/Functions";

const ACTION = {
  changeInput: "changeInput",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "changeInput":
      return {
        ...state,
        [action.key]: action.value,
      };

    default:
      break;
  }
};

const getActualValue = (value) => {
  if (value > 10) return 1;
  return 0;
};

// main function
const IADL = ({disable, nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadFisik, setLoading} = useContext(FungsiFisikContext);
  const [state, dispatch] = useReducer(reducer, {
    ask1: 100,
    ask2: 100,
    ask3: 100,
    ask4: 100,
    ask5: 100,
    ask6: 100,
    ask7: 100,
    ask8: 100,
  });

  const handleSubmit = async () => {
    const error = radioErrorCheck(Object.values(state));
    if (error.length > 0) return alert(radioMessageTemplate(error));

    // console.log(state);

    const arrResult =
      Object.values(state).map((item) => {
        return getActualValue(parseInt(item));
      }) || [];

    const result = arrResult.reduce((a, b) => a + b);

    // console.log(arrResult);
    // console.log(result);

    const payload = {
      fungsiFisik: {
        ...payloadFisik,
        iadl: true,
      },
      resultIadl: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };

    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        let message;

        if (result <= 2) message = "Mengalami gangguan IADL";
        if (result > 2) message = "Tidak mengalami gangguan IADL";

        nextStep(message);
        reload();
      }
    );
  };

  return (
    <>
      <FormControl>
        {Object.keys(IadlInput).map((item, index) => (
          <Box key={index} sx={{marginTop: 2}}>
            <MuiRadio
              label={`${index + 1}. ${IadlInput[item].label}`}
              options={IadlInput[item].answer}
              value={state[item]}
              onChange={(e) =>
                dispatch({
                  type: ACTION.changeInput,
                  key: item,
                  value: e.target.value,
                })
              }
            />
          </Box>
        ))}
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        sx={{marginTop: 5}}
        endIcon={<SaveRounded />}
        onClick={handleSubmit}
        disabled={disable}
      >
        Submit
      </Button>
    </>
  );
};

export default IADL;
