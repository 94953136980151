export const IadlInput = {
  ask1: {
    label: "Kemampuan menggunakan telepon",
    answer: [
      {
        label:
          "Mengoperasikan telepon sendiri dan mencari dan menghubungi nomor",
        value: 11,
      },
      {
        label: "Menghubungi beberapa nomor yang diketahui",
        value: 12,
      },
      {
        label: "Menjawab telepon tetapi tidak menghubungi",
        value: 13,
      },
      {
        label: "Tidak bisa menggunakan telepon sama sekali",
        value: 1,
      },
    ],
  },
  ask2: {
    label: "Kemampuan pergi ke suatu tempat",
    answer: [
      {
        label:
          "Bepergian sendiri menggunakan kendaraan umum atau menyetir sendiri",
        value: 11,
      },
      {
        label: "Mengatur dan melakukan perjalanan sendiri",
        value: 12,
      },
      {
        label:
          "Perjalanan menggunakan transportasi umum jika ada yang menyertai",
        value: 13,
      },
      {
        label: "Tidak melakukan perjalanan sama sekali",
        value: 1,
      },
    ],
  },
  ask3: {
    label: "Kemampuan berbelanja",
    answer: [
      {
        label: "Mengatur semua kebutuhan belanja sendiri",
        value: 11,
      },
      {
        label: "Perlu bantuan untuk mengantar belanja",
        value: 1,
      },
      {
        label: "Sama sekali tidak mampu belanja",
        value: 2,
      },
    ],
  },
  ask4: {
    label: "Kemampuan menyiapkan makanan",
    answer: [
      {
        label: "Merencanakan, menyiapkan, dan menghidangkan makanan",
        value: 11,
      },
      {
        label: "Menyiapkan makanan jika sudah tersedia bahan makanan",
        value: 1,
      },
      {
        label: "Menyiapkan makanan tetapi tidak mengatur diet yang cukup",
        value: 2,
      },
      {
        label: "Perlu disiapkan dan dilayani",
        value: 3,
      },
    ],
  },
  ask5: {
    label: "Kemampuan melakukan pekerjaan rumah",
    answer: [
      {
        label: "Merawat rumah sendiri atau bantuan kadang-kadang",
        value: 11,
      },
      {
        label:
          "Mengerjakan pekerjaan ringan sehari-hari (merapikan tempat tidur, mencuci piring)",
        value: 12,
      },
      {
        label: "Perlu bantuan untuk semua perawatan rumah sehari-hari",
        value: 13,
      },
      {
        label: "Tidak berpartisipasi dalam rumah",
        value: 3,
      },
    ],
  },
  ask6: {
    label: "Kemampuan mencuci pakaian",
    answer: [
      {
        label: "Mencuci semua pakaian sendiri",
        value: 11,
      },
      {
        label: "Mencuci pakaian yang kecil",
        value: 12,
      },
      {
        label: "Semua pakaian dicuci oleh orang lain",
        value: 1,
      },
    ],
  },
  ask7: {
    label: "Kemampuan mengatur obat-obatan",
    answer: [
      {
        label: "Meminum obat secara tepat dosis dan waktu tanpa bantuan",
        value: 11,
      },
      {
        label: "Tidak mampu menyiapkan obat sendiri",
        value: 1,
      },
    ],
  },
  ask8: {
    label: "Kemampuan mengatur keuangan",
    answer: [
      {
        label: "Mengatur masalah financial (tagihan, pergi ke bank)",
        value: 11,
      },
      {
        label:
          "Mengatur pengeluaran sehari-hari, tapi perlu bantuan untuk ke bank untuk transaksi penting",
        value: 12,
      },
      {
        label: "Tidak mampu mengambil keputusan financial atau memegang uang",
        value: 1,
      },
    ],
  },
};
