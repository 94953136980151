import React from "react";
import { Form, Input } from "antd";
import Barlow from "../../../../Components/Typography/Barlow";
import { Input as MuiInput, InputAdornment } from "@mui/material";

const TandaVitalInput = {};

const rulesInput = [
  {
    required: true,
    message: "field harus di isi!",
  },
];

const TekananDarahInput = () => {
  return (
    <div>
      <Barlow variant="body1" sx={{ marginBottom: 1 }}>
        Tekanan Darah
      </Barlow>
      <div className="divTekananDarah">
        <Form.Item
          className="inputTekananDarah"
          name="tekananDarah1"
          rules={rulesInput}
        >
          <MuiInput placeholder="...." fullWidth type="number" />
        </Form.Item>
        {"/"}
        <Form.Item
          className="inputTekananDarah"
          name="tekananDarah2"
          rules={rulesInput}
        >
          <MuiInput
            placeholder="...."
            endAdornment={<InputAdornment>mmHg</InputAdornment>}
            type="number"
            fullWidth
          />
        </Form.Item>
      </div>
    </div>
  );
};

const NadiInput = ({ name = "nadi", label = "Nadi", unit = "kali/menit" }) => {
  return (
    <div>
      <Barlow variant="body1" sx={{ marginBottom: 1 }}>
        {label}
      </Barlow>
      <div className="divTekananDarah">
        <Form.Item name={name} rules={rulesInput} style={{ width: "100%" }}>
          <MuiInput
            placeholder="...."
            style={{ borderRadius: 3 }}
            endAdornment={<InputAdornment>{unit}</InputAdornment>}
            type="number"
            fullWidth
          />
        </Form.Item>
      </div>
    </div>
  );
};

const RespirasiInput = () => {
  return <NadiInput name="respirasi" label="Respirasi" unit="kali/menit" />;
};

const SuhuInput = () => {
  return <NadiInput name="suhu" label="Suhu" unit={<>&#176;C</>} />;
};

TandaVitalInput.Nadi = NadiInput;
TandaVitalInput.Suhu = SuhuInput;
TandaVitalInput.Respirasi = RespirasiInput;
TandaVitalInput.TekananDarah = TekananDarahInput;

export default TandaVitalInput;
