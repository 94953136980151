import React from "react";

import {Stack} from "@mui/material";

import {GeneralCard} from "../../Components/Card";
import Barlow from "../../Components/Typography/Barlow";

const Lokasi = () => {
  return (
    <Stack spacing={2}>
      <Barlow variant="h3">Lokasi</Barlow>
      <GeneralCard
        sx={{
          minHeight: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Barlow variant="h4">
          Sedang dalam tahap pengembangan developer...
        </Barlow>
      </GeneralCard>
    </Stack>
  );
};

export default Lokasi;
