import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useReducer,
  useState,
} from "react";
import {AuthContext} from "../../../Layouts";

// libs import
import {Stepper, Step, StepLabel, Stack, Box, StepContent} from "@mui/material";

// components import
import {getTaskProgress} from "../../../Services/Periksa";
import LoadingComponent from "../../../Components/Loading/LoadingComponent";
import Tahap1 from "./Tahap1";
import Tahap2 from "./Tahap2";
import Tahap3 from "./Tahap3";
import {PopupSuccess} from "../../../Components/Popup";

// static variable

export const KognitifContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "changeFungsiKognitif":
      return {
        ...state,
        fungsiKognitif: {
          ...state.fungsiKognitif,
          [action.key]: action.value,
        },
      };

    case "loading":
      return {
        ...state,
        loading: action.value,
      };

    case "nextStep":
      return {
        ...state,
        stepValue: action.value,
        message: action.message,
        popupSuccess: true,
      };

    case "closePopup":
      return {
        ...state,
        popupSuccess: false,
        message: "",
      };

    case "render":
      return {
        ...state,
        render: !state.render,
      };

    case "skipStep":
      return {
        ...state,
        stepValue: action.value,
      };

    default:
      break;
  }
};

const ACTION = {
  changeKognitif: "changeFungsiKognitif",
  loading: "loading",
  nextStep: "nextStep",
  closePopup: "closePopup",
  render: "render",
  skipStep: "skipStep",
};

const FungsiKognitif = ({nextTab}) => {
  const {currentUser} = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, {
    fungsiKognitif: {
      tahap1: false,
      tahap2: false,
      tahap3: false,
    },
    loading: false,
    stepValue: 0,
    popupSuccess: false,
    message: "",
    render: false,
  });

  const [payloadKognitif, setPayloadKognitif] = useState({});

  const loaderHandler = (bool) => dispatch({type: ACTION.loading, value: bool});

  useLayoutEffect(() => {
    getTaskProgress(currentUser.userId, (bool) =>
      dispatch({type: ACTION.loading, value: bool})
    ).then((res) => {
      if (res?.status === 400)
        return "error saat mengambil data Task Progress !";

      // console.log(res.data());
      // console.log(state.stepValue);
      const {fungsiKognitif} = res.data();
      setPayloadKognitif(res.data().fungsiKognitif);

      if (fungsiKognitif.tahap1) dispatch({type: ACTION.skipStep, value: 1});
      if (fungsiKognitif.tahap2) dispatch({type: ACTION.skipStep, value: 2});
    });
  }, [state.render]);

  return (
    <KognitifContext.Provider
      value={{
        payloadKognitif,
        loaderHandler,
      }}
    >
      <PopupSuccess
        open={state.popupSuccess}
        label={state.message}
        onClose={() => dispatch({type: ACTION.closePopup})}
      />
      {state.loading ? (
        <LoadingComponent />
      ) : (
        <Stack spacing={3}>
          <Stepper activeStep={state.stepValue} orientation="vertical">
            <Step>
              <StepLabel>Tahap 1</StepLabel>
              <StepContent>
                <Tahap1
                  reload={() => dispatch({type: ACTION.render})}
                  nextStep={(value, message) =>
                    dispatch({
                      type: ACTION.nextStep,
                      message: message,
                      value: value,
                    })
                  }
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Tahap 2</StepLabel>
              <StepContent>
                <Tahap2
                  reload={() => dispatch({type: ACTION.render})}
                  nextStep={(value, message) =>
                    dispatch({
                      type: ACTION.nextStep,
                      message: message,
                      value: value,
                    })
                  }
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Tahap 3</StepLabel>
              <StepContent>
                <Tahap3
                  reload={() => dispatch({type: ACTION.render})}
                  nextStep={(value, message) =>
                    dispatch({
                      type: ACTION.nextStep,
                      message: message,
                      value: value,
                    })
                  }
                />
              </StepContent>
            </Step>
          </Stepper>
        </Stack>
      )}
    </KognitifContext.Provider>
  );
};

export default FungsiKognitif;
