import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../Layouts";

// libs import
import {
  Stack,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Table,
  Paper,
  Button,
} from "@mui/material";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";

// components import
import Barlow from "../../../Components/Typography/Barlow";
import {GeneralCard} from "../../../Components/Card";
import {getAllUsers} from "../../../Services/Auth";
import LoadingComponent from "../../../Components/Loading/LoadingComponent";

// static variable
const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const myToken = process.env.REACT_APP_LOCAL_TOKEN;

const DashboardAdmin = () => {
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let condition = true;

    if (currentUser.role !== "admin") {
      navigate("/");
      return alert("Anda bukan administrator!");
    }

    getAllUsers(setIsLoading).then((res) => {
      if (res?.status !== 400) {
        const sortedbyName = res.docs.sort((a, b) =>
          a.data().nama.localeCompare(b.data().nama)
        );
        setData(
          sortedbyName.map((item) => {
            return {
              name: item.data().nama,
              gender: item.data().jk === "male" ? "Laki-Laki" : "Perempuan",
              birthDate: dayjs(item.data().tanggalLahir * 1000).format(
                "D MMMM YYYY"
              ),
              nik: item.data().nik,
              address: item.data().alamat,
              profession: item.data().pekerjaan,
              action: (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{fontSize: 11}}
                  onClick={() =>
                    navigate(
                      `/detail?token=${myToken}&user=${item.id}&name=${
                        item.data().nama
                      }`
                    )
                  }
                >
                  Detail
                </Button>
              ),
            };
          })
        );
      }
    });

    return () => {
      condition = false;
    };
  }, []);

  return (
    <Stack spacing={3}>
      <Barlow variant="h3">User's Table</Barlow>
      {isLoading ? (
        <GeneralCard sx={{height: 300}}>
          <LoadingComponent />
        </GeneralCard>
      ) : (
        <GeneralCard sx={{padding: 3}}>
          <TableContainer
            component={Paper}
            sx={{
              maxWidth: "100%",
              overflow: "auto",
              minHeight: 500,
              maxHeight: 500,
            }}
          >
            <Table
              sx={{
                width: "max-content",
                minWidth: "100%",
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">No</StyledTableCell>
                  <StyledTableCell align="center">Nama</StyledTableCell>
                  <StyledTableCell align="center">
                    Jenis Kelamin
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Tanggal Lahir
                  </StyledTableCell>
                  <StyledTableCell align="center">NIK</StyledTableCell>
                  <StyledTableCell align="center">Alamat</StyledTableCell>
                  <StyledTableCell align="center">Pekerjaan</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 &&
                  data.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      <StyledTableCell>{item.gender}</StyledTableCell>
                      <StyledTableCell>{item.birthDate}</StyledTableCell>
                      <StyledTableCell>{item.nik}</StyledTableCell>
                      <StyledTableCell>{item.address}</StyledTableCell>
                      <StyledTableCell>{item.profession}</StyledTableCell>
                      <StyledTableCell>{item.action}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </GeneralCard>
      )}
    </Stack>
  );
};

export default DashboardAdmin;
