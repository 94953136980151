import React from "react";

// libs import
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/material";

// components import
import Barlow from "../../../Components/Typography/Barlow";
import { GeneralCard } from "../../../Components/Card";
import Charts from "../../../Components/Charts";

const ChartComponent = ({
  title = "",
  content = [],
  md = 6,
  contentKey = ["result"],
  color = ["#8884d8"],
}) => {
  return (
    <Grid xs={12} md={md}>
      <GeneralCard>
        <Stack spacing={2} sx={{ height: 300, padding: 3 }}>
          <Barlow textAlign={"center"} variant="h6">
            {title}
          </Barlow>
          <Charts
            content={content}
            contentKey={contentKey}
            label={"date"}
            color={color}
          />
        </Stack>
      </GeneralCard>
    </Grid>
  );
};

export default ChartComponent;
