import React, {useLayoutEffect, useState} from "react";

// libs import
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {Box, Button} from "@mui/material";
import {ChevronLeftRounded, ChevronRightRounded} from "@mui/icons-material";

// components import
import {GeneralCard} from "../../../Components/Card";

// static variable
const lastIndex = (arr = []) => {
  return arr.length - 1;
};

const TabPanel = ({list = [], changePage}) => {
  const [currentPhoto, setPhoto] = useState(0);

  const handleBack = () => {
    setPhoto((prev) => prev - 1);
  };

  const handleNext = () => {
    setPhoto((prev) => prev + 1);
  };

  useLayoutEffect(() => {
    setPhoto((prev) => (prev = 0));
  }, [changePage]);

  return (
    <>
      <GeneralCard
        sx={{
          paddingX: {md: 5, xs: 1},
          paddingY: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: {xs: 1, md: 5},
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            startIcon={<ChevronLeftRounded />}
            label="Back"
            color="primary"
            variant="contained"
            disabled={currentPhoto === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            endIcon={<ChevronRightRounded />}
            label="Next"
            color="primary"
            variant="contained"
            disabled={currentPhoto === lastIndex(list)}
            onClick={handleNext}
          >
            Next
          </Button>
        </Box>
        <div style={{width: "100%", overflow: "auto"}}>
          <img
            style={{borderRadius: 15, width: "100%"}}
            src={list[currentPhoto]}
            alt=""
            onClick={() => window.open(list[currentPhoto])}
          />
        </div>
      </GeneralCard>
    </>
  );
};

export default TabPanel;
