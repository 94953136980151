import {db} from "../../Helper/Firebase";
import {
  setDoc,
  doc,
  updateDoc,
  getDoc,
  collection,
  addDoc,
} from "firebase/firestore";

const collectionName = "task-progress";

const collectionFisik = "result-fungsi-fisik";

const collectionPsikologis = "result-kondisi-psikologis";

const collectionKognitif = "result-fungsi-kognitif";

const collectionDukunganSosial = "result-dukungan-sosial";

const docTaskProgress = (userId) => {
  return doc(db, collectionName, userId);
};

const docFisik = collection(db, collectionFisik);

const docPsikologis = collection(db, collectionPsikologis);

const docKognitif = collection(db, collectionKognitif);

const docDukungan = collection(db, collectionDukunganSosial);

const initialTask = {
  fungsiFisik: {
    adl: false,
    iadl: false,
    imt: false,
    resikoJatuh: false,
    statusKerentaan: false,
    nyeri: false,
    resultImt: 0,
    resultAdl: 0,
    resultIadl: 0,
    resultNyeri: 0,
    resultResiko: 0,
    resultKerentaan: "",
  },
  fungsiKognitif: {
    tahap1: false,
    tahap2: false,
    resultTahap2: 0,
    tahap3: false,
  },
  statusPsikologi: false,
  resultPsikologi: 0,
  statusSosial: false,
  resultSosial: 0,
};

export const setTaskProgress = async (userId, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await setDoc(docTaskProgress(userId), initialTask);
    loaderHandler(false);
    return {...result, status: 200};
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const getTaskProgress = async (userId, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await getDoc(docTaskProgress(userId));
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const updateTaskProgress = async (userId, payload, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await updateDoc(docTaskProgress(userId), payload);
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const resetTaskProgress = async (userId, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await updateDoc(docTaskProgress(userId), initialTask);
    loaderHandler(false);
    return {...result, status: 200};
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const setResultFisik = async (payload, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await addDoc(docFisik, payload);
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const setResultPsikologis = async (payload, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await addDoc(docPsikologis, payload);
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const setResultKognitif = async (payload, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await addDoc(docKognitif, payload);
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const setResultDukungan = async (payload, loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await addDoc(docDukungan, payload);
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};
