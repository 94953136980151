import React, {
  useContext,
  useLayoutEffect,
  useReducer,
  createContext,
  useState,
} from "react";
import {AuthContext} from "../../../Layouts";

// libs import
import {Stepper, Step, StepLabel, Box, StepContent} from "@mui/material";

// components import
import {getTaskProgress} from "../../../Services/Periksa";
import {PopupFailed, PopupSuccess} from "../../../Components/Popup";
import IMT from "./IMT";
import ADL from "./ADL";
import IADL from "./IADL";
import Nyeri from "./Nyeri";
import ResikoJatuh from "./ResikoJatuh";
import StatusKerentaan from "./StatusKerentaan";
import LoadingComponent from "../../../Components/Loading/LoadingComponent";

// static consts
const ACTION = {
  fail: "fail",
  success: "success",
  message: "message",
  closePopup: "closePopup",
  stepUp: "stepUp",
  setDisable: "setDisable",
  resetStep: "resetStep",
  skipStep: "skipStep",
};

// reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "fail":
      return {
        ...state,
        isFail: true,
        popupMessage: action.value,
      };
    case "success":
      return {
        ...state,
        isSuccess: true,
        popupMessage: action.value,
      };
    case "closePopup":
      return {
        ...state,
        isSuccess: false,
        isFail: false,
        popupMessage: "",
      };
    case "stepUp":
      return {
        ...state,
        isSuccess: true,
        stepValue: state.stepValue + 1,
        popupMessage: action.value,
      };
    case "setDisable":
      return {
        ...state,
        disablePage: {
          ...state.disablePage,
          ...action.payload,
        },
      };
    case "resetStep":
      return {
        ...state,
        stepValue: 0,
      };
    case "skipStep":
      return {
        ...state,
        stepValue: state.stepValue + action.value,
      };
    default:
      break;
  }
};

export const FungsiFisikContext = createContext();

const FungsiFisik = ({nextTab}) => {
  const {currentUser} = useContext(AuthContext);
  const [payloadFisik, setPayload] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    isFail: false,
    isSuccess: false,
    popupMessage: "",
    inputUser: {
      jenisKelamin: currentUser.jk,
      beratBadan: 0,
      tinggiLutut: 0,
      tinggiBadan: 0,
      umur: 0,
    },
    stepValue: 0,
    disablePage: {
      adl: false,
      iadl: false,
      imt: false,
      nyeri: false,
      resikoJatuh: false,
      statusKerentaan: false,
    },
  });

  useLayoutEffect(() => {
    getTaskProgress(currentUser.userId, setLoading).then((res) => {
      // if error while get task progress
      if (res.status === 400) {
        return dispatch({
          type: ACTION.fail,
          value: "Terjadi kesalahan ketika membaca Task Progress !",
        });
      }

      const {fungsiFisik} = res.data();

      // console.log(fungsiFisik);

      setPayload(fungsiFisik);

      dispatch({type: ACTION.resetStep});

      if (fungsiFisik.statusKerentaan)
        return nextTab(
          1,
          state.popupMessage
            ? state.popupMessage
            : "Progress Fungsi Fisik telah selesai"
        );
      if (fungsiFisik.resikoJatuh)
        return dispatch({type: ACTION.skipStep, value: 5});
      if (fungsiFisik.nyeri) return dispatch({type: ACTION.skipStep, value: 4});
      if (fungsiFisik.iadl) return dispatch({type: ACTION.skipStep, value: 3});
      if (fungsiFisik.adl) return dispatch({type: ACTION.skipStep, value: 2});
      if (fungsiFisik.imt) return dispatch({type: ACTION.skipStep, value: 1});

      return dispatch({
        type: ACTION.setDisable,
        payload: fungsiFisik,
      });
    });
  }, [reload]);

  return (
    <FungsiFisikContext.Provider
      value={{
        payloadFisik,
        setLoading,
      }}
    >
      <PopupFailed
        open={state.isFail}
        label={state.popupMessage}
        onClose={() => dispatch({type: ACTION.closePopup})}
      />
      <PopupSuccess
        open={state.isSuccess}
        label={state.popupMessage}
        onClose={() => dispatch({type: ACTION.closePopup})}
      />
      <Box minHeight={400} position={"relative"} sx={{width: "100%"}}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {/* <Stepper
              activeStep={state.stepValue}
              sx={{display: {xs: "none", md: "flex"}}}
              alternativeLabel
            >
              <Step>
                <StepLabel>IMT (Index Massa Tubuh)</StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  PENILAIAN Fungsional AKTIVITAS HIDUP SEHARI-HARI (ADL)
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  PENILAIAN Fungsional INSTRUMENTAL AKTIVITAS HIDUP SEHARI-HARI
                  (IADL)
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>Nyeri</StepLabel>
              </Step>
              <Step>
                <StepLabel>Resiko jatuh</StepLabel>
              </Step>
              <Step>
                <StepLabel>Status Kerentaan</StepLabel>
              </Step>
            </Stepper> */}

            {/* XS stepper start */}
            <Stepper
              activeStep={state.stepValue}
              orientation="vertical"
            >
              <Step>
                <StepLabel>IMT (Index Massa Tubuh)</StepLabel>
                <StepContent>
                  <IMT
                    disable={state.disablePage.imt}
                    nextStep={(message) =>
                      dispatch({type: ACTION.stepUp, value: message})
                    }
                    reload={() => setReload((prev) => !prev)}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  PENILAIAN Fungsional AKTIVITAS HIDUP SEHARI-HARI (ADL)
                </StepLabel>
                <StepContent>
                  <ADL
                    disable={state.disablePage.adl}
                    nextStep={(message) =>
                      dispatch({type: ACTION.stepUp, value: message})
                    }
                    reload={() => setReload((prev) => !prev)}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  PENILAIAN Fungsional INSTRUMENTAL AKTIVITAS HIDUP SEHARI-HARI
                  (IADL)
                </StepLabel>
                <StepContent>
                  <IADL
                    disable={state.disablePage.iadl}
                    nextStep={(message) =>
                      dispatch({type: ACTION.stepUp, value: message})
                    }
                    reload={() => setReload((prev) => !prev)}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Nyeri</StepLabel>
                <StepContent>
                  <Nyeri
                    disable={state.disablePage.nyeri}
                    nextStep={(message) =>
                      dispatch({type: ACTION.stepUp, value: message})
                    }
                    reload={() => setReload((prev) => !prev)}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Resiko jatuh</StepLabel>
                <StepContent>
                  <ResikoJatuh
                    disable={state.disablePage.resikoJatuh}
                    nextStep={(message) =>
                      dispatch({type: ACTION.stepUp, value: message})
                    }
                    reload={() => setReload((prev) => !prev)}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Status Kerentaan</StepLabel>
                <StepContent>
                  <StatusKerentaan
                    disable={state.disablePage.statusKerentaan}
                    nextStep={(message) =>
                      dispatch({type: ACTION.stepUp, value: message})
                    }
                    reload={() => setReload((prev) => !prev)}
                  />
                </StepContent>
              </Step>
            </Stepper>
          </>
        )}
      </Box>

      {/* next button */}
    </FungsiFisikContext.Provider>
  );
};

export default FungsiFisik;
