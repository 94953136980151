import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Barlow from "../../Components/Typography/Barlow";

import Image1 from "../../Assets/icon/1.svg";
import Image2 from "../../Assets/icon/2.svg";
import Image3 from "../../Assets/icon/3.svg";
import Image4 from "../../Assets/icon/4.svg";
import Image5 from "../../Assets/icon/5.png";
import Image6 from "../../Assets/icon/6.png";
import Image7 from "../../Assets/icon/7.png";
import Image8 from "../../Assets/icon/8.svg";
import Image9 from "../../Assets/icon/9.svg";

export const StatusKerentaanInput = {
  ask1: {
    label: "Pilihlah salah satu kondisi dibawah ini sesuai dengan situasi anda saat ini",
    answers: [
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image1}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda merasa sangat sehat, aktif, energik dan memiliki motivasi
                yang tinggi. Anda biasanya teratur berolahraga dan paling sehat
                diantara orang yang seumuran
              </Grid>
            </Grid>
          </>
        ),
        value: 1,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image2}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda tidak memiliki gejala penyakit, namun sedikit kurang fit
                dibandingkan orang di kelompok pertama
              </Grid>
            </Grid>
          </>
        ),
        value: 2,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image3}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda memiliki masalah kesehatan, namun masih bisa teratasi.
                Selain berjalan, anda tidak melakukan aktivitas fisik lain
                secara teratur
              </Grid>
            </Grid>
          </>
        ),
        value: 3,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image4}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda tidak tergantung bantuan orang lain, namun memiliki
                gangguan penyakit yang kadang mengganggu aktivitas. Sering
                mengeluh cepat capek dan atau lamban Sedikit rentan
              </Grid>
            </Grid>
          </>
        ),
        value: 4,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image5}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda membutuhkan bantuan orang lain untuk melakukan kegiatan
                sehari-hari instrumental (mengurus keuangan, transportasi,
                pekerjaan rumah yang berat, berobat). Biasanya juga membutuhkan
                bantuan untuk berbelanja dan berjalan diluar, menyiapkan makanan
                dan pekerjaan rumah lainnya
              </Grid>
            </Grid>
          </>
        ),
        value: 5,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image6}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda membutuhkan bantuan untuk melakukan kegiatan di luar rumah.
                Anda kesulitan naik turun tangga dan membutuhkan bantuan untuk
                mandi dan mungkin menggunakan pakaian
              </Grid>
            </Grid>
          </>
        ),
        value: 6,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image7}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda sepenuhnya tergantung pada orang lain baik dari segi fisik
                maupun kognitif. Namun terlihat stabil dan tidak berisiko tinggi
                mengalami hal fatal (meninggal) setidaknya dalam 6 bulan kedepan
              </Grid>
            </Grid>
          </>
        ),
        value: 7,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image8}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Anda sepenuhnya tergantung pada orang lain, mendekati akhir
                hayat, bahkan sakit ringanpun bisa fatal akibatnya
              </Grid>
            </Grid>
          </>
        ),
        value: 8,
      },
      {
        label: (
          <>
            <Grid
              container
              spacing={2}
              sx={{flexDirection: {md: "row", xs: "column"}}}
            >
              <Grid md={2} xs={12} justifyContent={"center"} display="flex">
                <img
                  src={Image9}
                  style={{height: 100, aspectRatio: 1 / 1}}
                  alt=""
                />
              </Grid>
              <Grid md={10} xs={12} textAlign="justify">
                Kategori ini berlaku bagi lansia yang memiliki harapan hidup{" "}
                {"<6"} bulan, walaupun kelihatan sebelumnya tidak membutuhkan
                bantuan. Banyak lansia yang mendekati akhir hayat masih mampu
                melakukan aktivitas
              </Grid>
            </Grid>
          </>
        ),
        value: 9,
      },
    ],
  },
};
