export const ResikoJatuhInput = {
  ask1: {
    label:
      "Perhatikan cara berjalan pasien saat akan duduk di kursi. Apakah pasien tampak tidak seimbang (sempoyongan/limbung)",
    answers: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  ask2: {
    label:
      "Apakah pasien memegang pinggiran kursi atau meja atau benda lain sebagai penopang saat akan duduk?",
    answers: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
};
