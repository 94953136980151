// Diabetes
import DImage1 from "../../Assets/Poster/Diabetes/diabetes1.jpeg";
import DImage2 from "../../Assets/Poster/Diabetes/diabetes2.jpeg";
import DImage3 from "../../Assets/Poster/Diabetes/diabetes3.jpeg";

// Gaya Hidup
import GImage1 from "../../Assets/Poster/Gaya Hidup/Gaya Hidup1.jpeg";
import GImage2 from "../../Assets/Poster/Gaya Hidup/Gaya Hidup2.jpeg";
import GImage3 from "../../Assets/Poster/Gaya Hidup/Gaya Hidup3.jpeg";
import GImage4 from "../../Assets/Poster/Gaya Hidup/Gaya Hidup4.jpeg";

// Hipertensi
import HImage1 from "../../Assets/Poster/Hipertensi/Hipertensi1.jpeg";
import HImage2 from "../../Assets/Poster/Hipertensi/Hipertensi2.jpeg";
import HImage3 from "../../Assets/Poster/Hipertensi/Hipertensi3.jpeg";

// Kognitif
import KImage1 from "../../Assets/Poster/Kognitif/kognitif1.jpeg";
import KImage2 from "../../Assets/Poster/Kognitif/kognitif2.jpeg";
import KImage3 from "../../Assets/Poster/Kognitif/kognitif3.jpeg";

const DiabetesList = [DImage1, DImage2, DImage3];

const GayaHidupList = [GImage1, GImage2, GImage3, GImage4];

const HipertensiList = [HImage1, HImage2, HImage3];

const KognitifList = [KImage1, KImage2, KImage3];

const InfoList = [DiabetesList, GayaHidupList, HipertensiList, KognitifList];

export default InfoList;
