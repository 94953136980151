import React from "react";
import {TextareaAutosize} from "@mui/material";

const style = {
  width: "calc(100% - 30px)",
  fontFamily: "Barlow",
  fontSize: 18,
  fontWeight: 500,
  padding: 15,
  border: "none",
  outline: "none",
};

const TextArea = ({ref, ...res}) => {
  return <TextareaAutosize minRows={6} maxRows={6} style={style} ref={ref} {...res} />;
};

export default TextArea;
