import {auth, db} from "../../Helper/Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  setDoc,
  doc,
  updateDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";

const usersRef = collection(db, "users");

export const register = async (email, password, loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    if (loaderHandler) loaderHandler(false);
    return result;
  } catch (error) {
    if (loaderHandler) loaderHandler(false);
  }
};

export const login = async (email, password, loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    if (loaderHandler) loaderHandler(false);
    return result;
  } catch (error) {
    if (loaderHandler) loaderHandler(false);
  }
};

export const setUserData = async (userId, payload, loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const docRef = doc(db, "users", userId);
    const result = await setDoc(docRef, payload);
    if (loaderHandler) loaderHandler(false);
    return result;
  } catch (error) {
    if (loaderHandler) loaderHandler(false);
  }
};

export const logout = async (loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const result = await signOut(auth);
    if (loaderHandler) loaderHandler(false);
    return result;
  } catch (error) {
    alert(`error while sign out\n${error}`);
    if (loaderHandler) loaderHandler(false);
  }
};

export const updateUserData = async (userId, payload, loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const docRef = doc(db, "users", userId);
    const result = await updateDoc(docRef, payload);
    if (loaderHandler) loaderHandler(false);
    return {...result, status: 200};
  } catch (error) {
    if (loaderHandler) loaderHandler(false);
    return {status: 400};
  }
};

export const forgotPassword = async (email) => {
  try {
    const result = await sendPasswordResetEmail(auth, email);
    return result;
  } catch (error) {
    return {status: 400};
  }
};

export const getAllUsers = async (loaderHandler) => {
  loaderHandler(true);
  try {
    const result = await getDocs(query(usersRef, where("role", "!=", "admin")));
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};
