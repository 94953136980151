import React, {useState, useEffect, useLayoutEffect} from "react";

// libs import
import {Layout} from "antd";
import {
  EmojiObjectsRounded,
  DirectionsRunRounded,
  HeartBrokenRounded,
  GroupRounded,
  MonitorHeartRounded,
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

// components import
import {GeneralCard} from "../../Components/Card";
import MuiTabs from "../../Components/Tabs";
import FungsiFisik from "./FungsiFisik";
import KondisiPsikologis from "./KondisiPsikologis";
import FungsiKognitif from "./FungsiKognitif";
import DukunganSosial from "./DukunganSosial";
import TandaVital from "./TandaVital";
import {PopupSuccess} from "../../Components/Popup";

// static consts
const windowsHash = window.location.hash;

const listTabs = [
  {
    icon: <DirectionsRunRounded />,
    label: "Fungsi Fisik",
    iconPosition: "end",
  },
  {
    icon: <HeartBrokenRounded />,
    label: "Kondisi Psikologis",
    iconPosition: "end",
  },
  {
    icon: <EmojiObjectsRounded />,
    label: "Fungsi Kognitif",
    iconPosition: "end",
  },
  {
    icon: <GroupRounded />,
    label: "Dukungan Sosial",
    iconPosition: "end",
  },
  {
    icon: <MonitorHeartRounded />,
    label: "Tanda Vital",
    iconPosition: "end",
  },
];

const hash = [
  "#fungsi-fisik",
  "#kondisi-psikologis",
  "#fungsi-kognitif",
  "#dukungan-sosial",
];

const Periksa = () => {
  const {Content} = Layout;
  const [tabValue, setTab] = useState(0);
  const [popupMessage, setPopup] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const nextTab = (tabValue, message) => {
    setTab(tabValue);
    setOpen(true);
    setPopup(message);
  };

  const closePopup = () => {
    setOpen(false);
    setPopup("");
  };

  useLayoutEffect(() => {
    if (windowsHash) {
      switch (windowsHash) {
        case "#fungsi-fisik":
          setTab(0);
          break;
        case "#kondisi-psikologis":
          setTab(1);
          break;
        case "#fungsi-kognitif":
          setTab(2);
          break;
        case "#dukungan-sosial":
          setTab(3);
          break;
        case "#tanda-vital":
          setTab(4);
          break;
        default:
          break;
      }
    }
  }, [windowsHash]);

  useEffect(() => {
    switch (tabValue) {
      case 0:
        navigate("#fungsi-fisik");
        break;
      case 1:
        navigate("#kondisi-psikologis");
        break;
      case 2:
        navigate("#fungsi-kognitif");
        break;
      case 3:
        navigate("#dukungan-sosial");
        break;
      case 4:
        navigate("#tanda-vital");
        break;
      default:
        // navigate("/not-found");
        break;
    }
  }, [tabValue]);

  useEffect(() => {
    navigate(windowsHash)
  }, [])

  return (
    <>
      <PopupSuccess open={open} label={popupMessage} onClose={closePopup} />
      <Layout style={{background: "inherit"}}>
        {/* Tabs */}
        <Content>
          <MuiTabs
            value={tabValue}
            listTabs={listTabs}
            onChange={(e) => setTab(parseInt(e))}
          />
        </Content>

        {/* Content */}
        <Content style={{marginTop: 30}}>
          <GeneralCard
            elevation={1}
            sx={{padding: "30px", minHeight: 500, overflowY: "auto"}}
          >
            {tabValue === 0 ? (
              <FungsiFisik nextTab={nextTab} />
            ) : tabValue === 1 ? (
              <KondisiPsikologis nextTab={nextTab} />
            ) : tabValue === 2 ? (
              <FungsiKognitif nextTab={nextTab} />
            ) : tabValue === 3 ? (
              <DukunganSosial nextTab={nextTab} />
            ) : (
              <TandaVital />
            )}
          </GeneralCard>
        </Content>
      </Layout>
    </>
  );
};

export default Periksa;
