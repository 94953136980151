import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../Layouts";
import { FungsiFisikContext } from "..";

// libs import
import { Box, Button, Stack } from "@mui/material";
import { SaveRounded } from "@mui/icons-material";

// component import
import Barlow from "../../../../Components/Typography/Barlow";
import { updateTaskProgress } from "../../../../Services/Periksa";
import { arrayUnion } from "firebase/firestore";

// static const
const number = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Nyeri = ({ disable, nextStep, reload }) => {
  const { currentUser } = useContext(AuthContext);
  const { payloadFisik, setLoading } = useContext(FungsiFisikContext);
  const [clickedNumber, setNumber] = useState(0);

  const handleSubmit = async () => {
    const payload = {
      fungsiFisik: {
        ...payloadFisik,
        nyeri: true,
      },
      resultNyeri: arrayUnion({
        date: new Date().getTime(),
        result: parseInt(clickedNumber),
      }),
    };

    await updateTaskProgress(currentUser.userId, payload, setLoading).then((res) => {
      if (res?.status === 400) return alert("error saat update task progress !");

      let message;

      if (clickedNumber === 0) message = "Tidak nyeri";
      if (clickedNumber > 0 && clickedNumber <= 3) message = "Nyeri ringan";
      if (clickedNumber > 3 && clickedNumber <= 6) message = "Nyeri sedang";
      if (clickedNumber > 6 && clickedNumber <= 10) message = "Nyeri berat";

      nextStep(message);
      reload();
    });
  };

  return (
    <Box minHeight={340} position="relative">
      <Stack spacing={4}>
        <Barlow variant="h6" textAlign={"center"}>
          Indikasi rasa nyeri yang di rasakan dengan menggunakan skala nyeri Numeric Rating Scale (0-10)
        </Barlow>
        <Barlow variant="h7" textAlign={"center"}>
          Klik pilihan dibawah ini, semakin besar nilai, berarti nyeri yang dirasakan semakin berat
        </Barlow>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1} sx={{ flexDirection: { xs: "column", md: "row" } }}>
          {number.map((numb) => (
            <Button key={numb} variant="contained" color={clickedNumber === numb ? "error" : "primary"} onClick={() => setNumber(numb)}>
              {numb}
            </Button>
          ))}
        </Box>
        {/* <Box display={"flex"} flexDirection="column" gap={1}>
          <Barlow fontWeight={600} variant="body1">
            *note :
          </Barlow>
          <Barlow fontWeight={600} variant="body2">
            &nbsp;&nbsp; -{">"} 0 Tidak Nyeri
          </Barlow>
          <Barlow fontWeight={600} variant="body2">
            &nbsp;&nbsp; -{">"} 1 - 3 Nyeri Ringan
          </Barlow>
          <Barlow fontWeight={600} variant="body2">
            &nbsp;&nbsp; -{">"} 4 - 6 Nyeri Sedang
          </Barlow>
          <Barlow fontWeight={600} variant="body2">
            &nbsp;&nbsp; -{">"} 7 - 10 Nyeri Berat
          </Barlow>
        </Box> */}
      </Stack>
      <Button
        sx={{
          position: { md: "absolute", xs: "static" },
          bottom: "0",
          marginTop: { md: 0, xs: 2 },
        }}
        variant="contained"
        color="primary"
        endIcon={<SaveRounded />}
        onClick={handleSubmit}
        disabled={disable}
        fullWidth
      >
        Submit
      </Button>
    </Box>
  );
};

export default Nyeri;
