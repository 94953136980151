import React from "react";

// lib import
import {BrowserRouter, Routes, Route} from "react-router-dom";

// component import
import CoreLayout from "../Layouts";
import LandingPage from "../Pages/LandingPage";
import Dashboard from "../Pages/Dashboard";
import Profile from "../Pages/Profile";
import Periksa from "../Pages/Periksa";
import Catatan from "../Pages/Catatan";
import Lokasi from "../Pages/Lokasi";
import Info from "../Pages/Info";
import DashboardAdmin from "../Pages/Admin/Dashboard";
import DetailUsers from "../Pages/Admin/Detail";

const Router = () => {
  return (
    <BrowserRouter>
      <CoreLayout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/dashboard-admin" element={<DashboardAdmin />} />
          <Route path="/detail" element={<DetailUsers />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/periksa" element={<Periksa />} />
          <Route path="/catatan" element={<Catatan />} />
          <Route path="/lokasi-lansia" element={<Lokasi />} />
          <Route path="/info" element={<Info />} />
        </Routes>
      </CoreLayout>
    </BrowserRouter>
  );
};

export default Router;
