import React, {useLayoutEffect, useState, useContext} from "react";

// libs import
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import MuiTextField from "../../Components/MuiTextField";
import dayjs from "dayjs";
import {
  AccountBoxRounded,
  CloseRounded,
  CloudUploadRounded,
  SaveRounded,
} from "@mui/icons-material";

// components import
import Barlow from "../../Components/Typography/Barlow";
import GeneralModal from "../../Components/Modal";
import {
  register,
  setUserData,
  login,
  forgotPassword,
} from "../../Services/Auth";
import {uploadProfilePicture} from "../../Services/Images";
import LoadingState from "../../Components/Loading/LoadingState";
import {PopupSuccess, PopupFailed} from "../../Components/Popup";
import UserInput from "../../Helper/UserInput";
import {AuthContext} from "../../Layouts";
import {setTaskProgress} from "../../Services/Periksa";

import Logo from "../../Assets/logo.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to={"/"}>E-Yang</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const optionJK = [
  {
    label: "Laki-Laki",
    value: "male",
  },
  {
    label: "Perempuan",
    value: "female",
  },
];

const initialValue = {
  nama: "",
  jk: "",
  nik: "",
  tanggalLahir: "",
  alamat: "",
  noHP: "",
  pekerjaan: "",
  email: "",
  password: "",
};

const initialImage = {
  file: "",
  objUrl: "",
};

const errorTemplate = (label, type) => {
  if (type === "select") return `pilih salah satu option dari ${label} !\n`;
  return `${label} harus di isi !\n`;
};

export default function LandingPage() {
  const navigate = useNavigate();
  const {setShow} = useContext(AuthContext);
  const {listInput, typeInput, labelInput, placeholderInput} = UserInput;
  const [tabValue, setTab] = useState(0);
  const [formInput, setInput] = useState(initialValue);
  const [imageInput, setImage] = useState(initialImage);
  const [open, setOpen] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isForgot, setForgot] = useState(false);
  const [isFailed, setFail] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const loaderHandler = (bool) => setLoading(bool);

  const onChange = (key, e, type) => {
    if (type === "date") setInput({...formInput, [key]: e});
    if (type !== "date") setInput({...formInput, [key]: e.target.value});
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    loaderHandler(true);

    const errorAlert = [];

    Object.values(formInput).map((item, index) => {
      if (!item)
        errorAlert.push(errorTemplate(labelInput[index], typeInput[index]));
    });

    if (!imageInput.file) errorAlert.push(errorTemplate("Foto", "text"));
    if (errorAlert.length > 0) {
      loaderHandler(false);
      return alert(errorAlert.join());
    }

    const payload = {
      ...formInput,
      tanggalLahir: dayjs(formInput.tanggalLahir) / 1000,
    };

    await register(formInput.email, formInput.password).then(async (res) => {
      // jika terjadi error saat registrasi user baru
      if (!res)
        return alert(
          "terjadi error saat registrasi user baru silahkan reload halaman ini!"
        );

      const uid = res.user.uid;
      console.log("registrasi berhasil!");

      // upload foto user
      await uploadProfilePicture(uid, imageInput.file).then(async (res) => {
        // jika terjadi error saat upload foto
        if (!res) return alert("terjadi error saat upload file foto!");

        console.log("upload gambar berhasil");
        delete payload.password;
        payload.userId = uid;
        payload.role = "user";

        // menambah dokumen user
        await setUserData(uid, payload, (bool) => {}).then(() => {
          // console.log("set user data berhasil, ", payload);
          setSuccess(true);
          setTab(0);
          setInput(initialValue);
        });

        await setTaskProgress(uid, loaderHandler);
      });
    });

    // navigate("/dashboard");
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    await login(formInput.email, formInput.password, loaderHandler)
      .then((res) => {
        // if (res.user.uid) navigate("/dashboard");
        loaderHandler(true);
      })
      .catch((err) => {
        setFail(true);
      });
  };

  const handleForgotPassword = async () => {
    await forgotPassword(formInput.email).then((res) => {
      if (res?.status !== 400) {
        setTab(0);
        setForgot(true);
      }
    });
  };

  const onChangeImage = (e) => {
    const fileImage = e.target.files[0];
    const objUrl = URL.createObjectURL(e.target.files[0]);

    setImage({file: fileImage, objUrl: objUrl});
  };

  useLayoutEffect(() => {
    setShow(false);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <PopupSuccess
            label={"Registrasi Berhasil !"}
            open={isSuccess}
            onClose={() => setSuccess(false)}
          />
          <PopupSuccess
            label={
              "Sistem telah mengirim form untuk lupa password, silahkan cek email anda !"
            }
            open={isForgot}
            onClose={() => setForgot(false)}
          />
          <PopupFailed
            open={isFailed}
            label="Emai/Password salah !"
            onClose={() => setFail(false)}
          />
          <GeneralModal open={open} onClose={() => setOpen(false)}>
            <Box
              component={"div"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {imageInput.objUrl ? (
                <img
                  src={imageInput.objUrl}
                  alt="profile"
                  style={{width: 200, height: 200, borderRadius: 8}}
                />
              ) : (
                <AccountBoxRounded
                  sx={{width: 200, height: 200}}
                  color="primary"
                />
              )}
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginTop: 2,
                }}
              >
                {imageInput.objUrl ? (
                  <>
                    <Button
                      startIcon={<SaveRounded />}
                      color="primary"
                      variant="contained"
                      onClick={() => setOpen(false)}
                    >
                      Simpan
                    </Button>
                    <Button
                      startIcon={<CloseRounded />}
                      color="error"
                      variant="contained"
                      onClick={() => setImage(initialImage)}
                    >
                      Batal
                    </Button>
                  </>
                ) : (
                  <Button component="label" startIcon={<CloudUploadRounded />}>
                    Upload Foto
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={onChangeImage}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </GeneralModal>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={2}
            sx={{backgroundColor: "inherit", padding: 2}}
          >
            <img src={Logo} style={{width: 40, height: 40}} alt="e-yang" />
            <Barlow variant="h4">E-Yang</Barlow>
          </Box>
          <Grid container component="main" sx={{height: "100vh"}}>
            <CssBaseline />
            {/* <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${Logo})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            /> */}
            <Grid
              item
              xs={12}
              component={Paper}
              elevation={6}
              square
              sx={{maxHeight: "100vh", overflowY: "auto"}}
            >
              {!tabValue ? (
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{m: 1, bgcolor: "inherit"}}>
                    <img
                      src={Logo}
                      style={{width: 40, height: 40}}
                      alt="e-yang"
                    />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleLogin}
                    sx={{mt: 1}}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Email Address"
                      autoFocus
                      type="email"
                      value={formInput.email}
                      onChange={(e) => onChange("email", e, "text")}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Password"
                      type="password"
                      value={formInput.password}
                      onChange={(e) => onChange("password", e, "text")}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{mt: 3, mb: 2}}
                    >
                      Sign In
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Barlow
                          sx={{cursor: "pointer"}}
                          onClick={() => setTab(2)}
                          variant="body2"
                        >
                          Forgot password?
                        </Barlow>
                      </Grid>
                      <Grid item>
                        <Barlow
                          sx={{cursor: "pointer"}}
                          onClick={() => setTab(1)}
                          variant="body2"
                        >
                          {"Don't have an account? Sign Up"}
                        </Barlow>
                      </Grid>
                    </Grid>
                    <Copyright sx={{mt: 5}} />
                  </Box>
                </Box>
              ) : tabValue == 1 ? (
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{m: 1, bgcolor: "inherit"}}>
                    <img
                      src={Logo}
                      style={{width: 40, height: 40}}
                      alt="e-yang"
                    />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign up
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleRegister}
                    sx={{mt: 1, maxWidth: 500}}
                  >
                    {Object.values(formInput).map((item, index) => (
                      <MuiTextField
                        key={index}
                        label={labelInput[index]}
                        value={item}
                        onChange={(e) =>
                          onChange(listInput[index], e, typeInput[index])
                        }
                        type={typeInput[index]}
                        SelectOptions={listInput[index] === "jk" && optionJK}
                        required
                        fullWidth
                        margin="normal"
                        placeholder={placeholderInput[index]}
                        autoFocus={index === 0 ? true : false}
                      />
                    ))}
                    <MuiTextField
                      label="Foto Profil"
                      type={"text"}
                      disabled
                      fullWidth
                      margin="normal"
                      onClick={() => setOpen(true)}
                      value={imageInput.file && imageInput.file.name}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{mt: 3, mb: 2}}
                    >
                      Sign In
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Barlow
                          sx={{cursor: "pointer"}}
                          onClick={() => setTab(2)}
                          variant="body2"
                        >
                          Forgot password?
                        </Barlow>
                      </Grid>
                      <Grid item>
                        <Barlow
                          sx={{cursor: "pointer"}}
                          onClick={() => setTab(0)}
                          variant="body2"
                        >
                          {"Have an account? Sign In"}
                        </Barlow>
                      </Grid>
                    </Grid>
                    <Copyright sx={{mt: 5}} />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{m: 1, bgcolor: "inherit"}}>
                    <img
                      src={Logo}
                      style={{width: 40, height: 40}}
                      alt="e-yang"
                    />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Forgot Password
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleForgotPassword}
                    sx={{mt: 1}}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Email Address"
                      autoFocus
                      type="email"
                      value={formInput.email}
                      onChange={(e) => onChange("email", e, "text")}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{mt: 3, mb: 2}}
                    >
                      Submit
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Barlow
                          sx={{cursor: "pointer"}}
                          onClick={() => setTab(0)}
                          variant="body2"
                        >
                          Sign In
                        </Barlow>
                      </Grid>
                      <Grid item>
                        <Barlow
                          sx={{cursor: "pointer"}}
                          onClick={() => setTab(1)}
                          variant="body2"
                        >
                          Sign Up
                        </Barlow>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
