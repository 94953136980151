import React, {useLayoutEffect} from "react";

// libs import
import {Stack} from "@mui/material";
import {useSearchParams, useNavigate} from "react-router-dom";

// components import
import Dashboard from "../../Dashboard";
import Barlow from "../../../Components/Typography/Barlow";
import Catatan from "../../Catatan";

// static variable
const myToken = process.env.REACT_APP_LOCAL_TOKEN;

const DetailUsers = () => {
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const userId = searchParam.get("user");
  const token = searchParam.get("token");
  const userName = searchParam.get("name");

  useLayoutEffect(() => {
    let condition = true;

    if (token !== myToken) {
      window.location.replace("/");
      return alert("token tidak valid!");
    }

    return () => {
      condition = false;
    };
  }, []);

  return (
    <>
      <Stack spacing={2}>
        <Barlow variant="h3" textAlign={"center"}>
          {userName}
        </Barlow>
        <Dashboard userId={userId} />
        <Catatan userId={userId} isHide />
      </Stack>
    </>
  );
};

export default DetailUsers;
