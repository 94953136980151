import React, {useContext, useState} from "react";
import {AuthContext} from "../../../../Layouts";
import {KognitifContext} from "..";

// libs import
import {Button, radioClasses, Stack} from "@mui/material";
import {PanoramaFishEyeRounded, SaveRounded} from "@mui/icons-material";

// components import
import MuiRadio from "../../../../Components/RadioButton";
import Barlow from "../../../../Components/Typography/Barlow";
import {updateTaskProgress} from "../../../../Services/Periksa";

// static variable
import {Tahap2Input} from "../../../../Helper/Tahap2Input";
import {
  radioErrorCheck,
  radioMessageTemplate,
} from "../../../../Services/Functions";

const Tahap2 = ({nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadKognitif, loaderHandler} = useContext(KognitifContext);
  const [radio, setRadio] = useState({
    ask1: 100,
    ask2: 100,
  });

  const handleChange = (key, e) => {
    setRadio({...radio, [key]: e.target.value});
  };

  const handleSubmit = async () => {
    const error = radioErrorCheck(Object.values(radio));
    if (error.length > 0) return alert(radioMessageTemplate(error));

    // console.log(radio);

    const result = Object.values(radio).reduce(
      (a, b) => parseInt(a) + parseInt(b)
    );

    const payload = {
      fungsiKognitif: {
        ...payloadKognitif,
        tahap2: true,
        resultTahap2: result,
      },
    };

    await updateTaskProgress(currentUser.userId, payload, loaderHandler).then(
      (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress");

        if (result === 0) {
          nextStep(2, "Tidak bisa melihat/membaca/menulis !");
          return reload();
        }

        nextStep(2, "Lanjut ke Tahap 3");
        reload();
      }
    );
  };

  return (
    <Stack spacing={3}>
      <Barlow variant="h4" textAlign={"center"}>
        Menggambar Jam
      </Barlow>
      <Barlow textAlign={"center"}>
        <PanoramaFishEyeRounded sx={{width: 200, height: 200}} />
      </Barlow>
      {Object.keys(Tahap2Input).map((item, index) => (
        <MuiRadio
          key={index}
          label={`${index + 1}. ${Tahap2Input[item].label}`}
          value={radio[item]}
          options={Tahap2Input[item].answers}
          onChange={(e) => handleChange(item, e)}
        />
      ))}

      <Button
        variant="contained"
        color="primary"
        endIcon={<SaveRounded />}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Stack>
  );
};

export default Tahap2;
