import React, {useMemo, useState} from "react";
import {
  Menu as MuiMenu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Divider,
} from "@mui/material";

const Menu = ({listMenu, label, isXsNone, isMdNone}) => {
  const [anchorEl, setAnchor] = useState(null);
  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const id = useMemo(() => {
    return open ? "simple-menu" : "";
  }, [open]);

  const handleClose = () => setAnchor(null);

  const handleClick = (e) => setAnchor(e.currentTarget);

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-describedby={id}
        aria-haspopup="true"
        sx={{
          display: {
            xs: isXsNone ? "none" : "flex",
            md: isMdNone ? "none" : "flex",
          },
        }}
      >
        {label}
      </IconButton>
      <MuiMenu
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          display: {
            xs: isXsNone ? "none" : "flex",
            md: isMdNone ? "none" : "flex",
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: "right", vertical: "top"}}
        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
      >
        {listMenu.map((menu, index) => (
          <ListMenu key={index} menu={menu} />
        ))}
      </MuiMenu>
    </>
  );
};

export default Menu;

const ListMenu = ({menu}) => {
  return (
    <>
      {menu?.label === "divider" ? (
        <Divider />
      ) : (
        <MenuItem onClick={menu?.action}>
          {menu.icon && (
            <ListItemIcon fontSize="small">{menu?.icon}</ListItemIcon>
          )}
          {menu?.label}
        </MenuItem>
      )}
    </>
  );
};
