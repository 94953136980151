import React, {useState, useLayoutEffect, useContext} from "react";
import {AuthContext} from "../../../Layouts";

// libs import
import {Button, Stack} from "@mui/material";
import {SaveRounded} from "@mui/icons-material";
import {arrayUnion} from "firebase/firestore";

// component import
import MuiRadio from "../../../Components/RadioButton";
import Barlow from "../../../Components/Typography/Barlow";
import LoadingComponent from "../../../Components/Loading/LoadingComponent";

// static variable
import {KondisiPsikologisInput} from "../../../Helper/KondisiPsikologisInput";
import {
  getTaskProgress,
  updateTaskProgress,
  setResultPsikologis,
} from "../../../Services/Periksa";
import {
  radioErrorCheck,
  radioMessageTemplate,
} from "../../../Services/Functions";

// main function
const KondisiPsikologis = ({nextTab}) => {
  const {currentUser} = useContext(AuthContext);

  const [taskProgress, setTaskProgress] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [radio, setRadio] = useState({
    ask1: 100,
    ask2: 100,
    ask3: 100,
    ask4: 100,
    ask5: 100,
  });

  const onChange = (key, e) => {
    setRadio({
      ...radio,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const error = radioErrorCheck(Object.values(radio));
    if (error.length > 0) return alert(radioMessageTemplate(error));

    // console.log("radio", radio)

    const result = Object.values(radio).reduce(
      (a, b) => parseInt(a) + parseInt(b)
    );

    const payload = {
      resultPsikologis: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };

    // console.log("payload", payload);

    // console.log("result", result);

    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      async (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        let message;
        if (result >= 2) message = "Mengalami gangguan psikologis";
        if (result < 2) message = "Tidak mengalami gangguan psikologis";

        nextTab(2, message);
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Stack spacing={2}>
          <Barlow variant="h6">
            Pengkajian ini menggunakan kuesioner Geriatric Depression Scale
            (GDS)
          </Barlow>
          {Object.keys(KondisiPsikologisInput).map((item, index) => (
            <MuiRadio
              key={index}
              label={
                <>
                  {index + 1}. {KondisiPsikologisInput[item].label}
                </>
              }
              value={radio[item]}
              onChange={(e) => onChange(item, e)}
              options={KondisiPsikologisInput[item].answers}
            />
          ))}
          <Button
            icon={<SaveRounded />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      )}
    </>
  );
};

export default KondisiPsikologis;
