const listInput = [
  "nama",
  "jk",
  "nik",
  "tanggalLahir",
  "alamat",
  "noHP",
  "pekerjaan",
  "email",
  "password",
];

const labelInput = [
  "Nama",
  "Jenis Kelamin",
  "NIK",
  "Tanggal Lahir",
  "Alamat",
  "Nomor HP",
  "Pekerjaan",
  "Email",
  "Password",
];

const typeInput = [
  "text",
  "select",
  "text",
  "date",
  "text",
  "number",
  "text",
  "email",
  "password",
];

const placeholderInput = [
  "Nama",
  "Jenis Kelamin",
  "NIK",
  "Tanggal Lahir",
  "Alamat",
  "085*********",
  "Pekerjaan",
  "Email",
  "Password",
];

const optionJK = [
  {
    label: "Laki-Laki",
    value: "male",
  },
  {
    label: "Perempuan",
    value: "female",
  },
];

const initialValue = {
  nama: "",
  jk: "",
  nik: "",
  tanggalLahir: "",
  alamat: "",
  noHP: "",
  pekerjaan: "",
  email: "",
  password: "",
};

const UserInput = {
  listInput,
  labelInput,
  typeInput,
  placeholderInput,
  optionJK,
  initialValue,
};

export default UserInput;
