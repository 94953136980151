import React, {useContext, useReducer} from "react";
import {AuthContext} from "../../../../Layouts";
import {FungsiFisikContext} from "..";

// libs import
import {Box, Button, Stack} from "@mui/material";
import Cookies from "js-cookies";

// component import
import MuiRadio from "../../../../Components/RadioButton";
import Barlow from "../../../../Components/Typography/Barlow";
import {updateTaskProgress} from "../../../../Services/Periksa";

// static const
import {ResikoJatuhInput} from "../../../../Helper/ResikoJatuhInput";
import {SaveRounded} from "@mui/icons-material";
import {arrayUnion} from "firebase/firestore";
import {
  radioErrorCheck,
  radioMessageTemplate,
} from "../../../../Services/Functions";

const ACTION = {
  changeRadio: "changeRadio",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "changeRadio":
      return {
        ...state,
        [action.key]: action.value,
      };

    default:
      break;
  }
};

// main function
const ResikoJatuh = ({disable, nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadFisik, setLoading} = useContext(FungsiFisikContext);
  const [state, dispatch] = useReducer(reducer, {
    ask1: 100,
    ask2: 100,
  });

  const handleSubmit = async () => {
    const error = radioErrorCheck(Object.values(state));
    if (error.length > 0) return alert(radioMessageTemplate(error));

    // console.log("state", state);

    const result = Object.values(state).reduce(
      (a, b) => parseInt(a) + parseInt(b)
    );
    // console.log("result", result)

    const payload = {
      fungsiFisik: {
        ...payloadFisik,
        resikoJatuh: true,
        resultResiko: result,
      },
      resultResiko: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };

    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        let message;
        if (result === 0) message = "Tidak berisiko jatuh";
        if (result === 1) message = "Risiko rendah";
        if (result === 2) message = "Risiko tinggi";

        // console.log("message", message)

        Cookies.setItem("resultResikoJatuh", result);
        nextStep(message);
        reload();
      }
    );
  };

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Barlow textAlign={"center"} variant="h6">
            Penilaian risiko jatuh menggunakan Kuesioner Modifikasi Get Up and
            Go (Sumber: RSUPN Cipto Mangunkusumo)
          </Barlow>
          <Barlow variant="subtitle2" sx={{fontSize: 18}}>
            Instruksikan pasien untuk duduk dari posisi berdiri
          </Barlow>
          {Object.keys(ResikoJatuhInput).map((item, index) => (
            <MuiRadio
              key={index}
              label={`${index + 1}. ${ResikoJatuhInput[item].label}`}
              value={state[item]}
              onChange={(e) =>
                dispatch({
                  type: ACTION.changeRadio,
                  key: item,
                  value: e.target.value,
                })
              }
              options={ResikoJatuhInput[item].answers}
            />
          ))}
          <Button
            variant="contained"
            color="primary"
            endIcon={<SaveRounded />}
            onClick={handleSubmit}
            disabled={disable}
            fullWidth
          >
            Simpan
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default ResikoJatuh;
