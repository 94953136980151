import {Stack} from "@mui/material";
import React, {useState} from "react";

// libs import

// components import
import MuiTabs from "../../Components/Tabs";
import TabPanel from "./TabPanel";

// static variable
import InfoList from "../../Helper/InfoList";

const listTabs = [
  {
    icon: "",
    label: "Diabetes Mellitus",
    iconPosition: "end",
  },
  {
    icon: "",
    label: "Gaya Hidup",
    iconPosition: "end",
  },
  {
    icon: "",
    label: "Hipertensi",
    iconPosition: "end",
  },
  {
    icon: "",
    label: "Kognitif dan Emosional",
    iconPosition: "end",
  },
];

const Info = () => {
  const [tab, setTab] = useState(0);
  const [renderPage, setRender] = useState(false);
  return (
    <>
      <Stack spacing={2} sx={{overflowX: "auto"}}>
        <MuiTabs
          listTabs={listTabs}
          value={tab}
          onChange={(e) => {
            setTab(parseInt(e));
            setRender((prev) => !prev);
          }}
        />
        <TabPanel list={InfoList[tab]} changePage={renderPage} />
      </Stack>
    </>
  );
};

export default Info;
