export const handleChangeForm = (onchange, value, key, e, type) => {
  if (type === "date") onchange({...value, [key]: e});
  if (type !== "date") onchange({...value, [key]: e.target.value});
};

export const radioErrorCheck = (arr = []) => {
  const error = [];
  arr.map((item, index) => {
    if (item === 100) error.push(`Nomor ${index + 1} harus di isi!\n`);
  });

  return error;
};

export const radioMessageTemplate = (arr = []) => {
  return `Ups ada yang belum di isi!\n${arr.join("")}`;
};
