export const Tahap2Input = {
  ask1: {
    label:
      "Apakah lansia dapat menuliskan 12 angka dengan benar (urut angka 1-12, angka 12,3, 6, 9 berada di keempat arah Bisang benar/ berseberangan)",
    answers: [
      {
        label: "Bisa",
        value: 1,
      },
      {
        label: "Tidak Bisa",
        value: 0,
      },
    ],
  },
  ask2: {
    label:
      "Apakah lansia dapat menggambar jarum jam dengan benar (panjang jarum jam tidak dinilai)",
    answers: [
      {
        label: "Bisa",
        value: 1,
      },
      {
        label: "Tidak Bisa",
        value: 0,
      },
    ],
  },
};
