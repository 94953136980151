import React, {useContext, useState} from "react";
import {AuthContext} from "../../../../Layouts";
import {KognitifContext} from "..";

// libs imports
import {
  Stack,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Button,
  Divider,
} from "@mui/material";
import {SaveRounded} from "@mui/icons-material";

// components import
import Barlow from "../../../../Components/Typography/Barlow";
import {updateTaskProgress} from "../../../../Services/Periksa";

// static variable

// main function
const Tahap1 = ({nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadKognitif, loaderHandler} = useContext(KognitifContext);
  const [radio, setRadio] = useState(100);

  const handleSubmit = async () => {
    if (radio === 100) return alert("Ups, pilih salah satu kondisi!");

    const payload = {
      fungsiKognitif: {
        ...payloadKognitif,
        tahap1: true,
        tahap2: radio === 0 ? true : false,
        resultTahap2: 0,
      },
    };

    await updateTaskProgress(currentUser.userId, payload, loaderHandler).then(
      (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        let message = "Lansia tidak dapat melihat/membaca/menulis";
        if (radio === 1) message = "Lansia dapat melihat/membaca/menulis";

        nextStep(radio === 1 ? 1 : 2, message);
        reload();
      }
    );
  };

  return (
    <>
      <Stack spacing={2} sx={{position: "relative", minHeight: 400}}>
        <Barlow variant="h6">
          PANDANG RESPONDEN DAN KATAKAN, “Mohon dengarkan dengan baik. Saya akan
          mengucapkan 3 kata dan saya ingin Bapak/ Ibu mengulang dan
          mengingatnya. Tiga kata tersebut adalah [DESA, DAPUR, BAYI].
        </Barlow>
        <Barlow variant="h6">
          Sekarang, giliran Ibu/Bapak untuk mengulangi kata tersebut”. (Catat
          kata yang diulangi dengan benar, tidak harus urut)
        </Barlow>
        <FormControl>
          <FormLabel>
            <Barlow variant="h6">
              Apakah lansia mampu mencatat kembali tiga kata di atas ?
            </Barlow>
          </FormLabel>
          <RadioGroup
            value={radio}
            onChange={(e) => {
              console.log(e.target.value);
              setRadio(parseInt(e.target.value));
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Ya"
              sx={{paddingTop: 1, paddingBottom: 1}}
            />
            <Divider />
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="Tidak"
              sx={{paddingTop: 1, paddingBottom: 1}}
            />
            <Divider />
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          endIcon={<SaveRounded />}
          onClick={handleSubmit}
          sx={{
            position: {md: "absolute", xs: "static"},
            marginTop: {md: 0, xs: 1},
            bottom: 0,
          }}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

export default Tahap1;
