export const Tahap3Input = {
  ask1: {
    label: "Bapak/ Ibu tolong ulangi 3 kata yang tadi saya sebutkan di awal !",
    answers: [
      {
        label: "Menyebutkan 3 kata benar",
        value: 3,
      },
      {
        label: "Menyebutkan 2 atau 1 kata benar",
        value: 2,
      },
      {
        label: "Hampir tidak pernah",
        value: 0,
      },
    ],
  },
};
