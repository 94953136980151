import {uploadBytes, ref, getDownloadURL} from "firebase/storage";
import {storage} from "../../Helper/Firebase";

export const uploadProfilePicture = async (userId, file, loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const storageRef = ref(storage, `users-picture/${userId}`);
    const result = await uploadBytes(storageRef, file);
    if (loaderHandler) loaderHandler(false);
    return {...result, status: 200};
  } catch (error) {
    if (loaderHandler) loaderHandler(false);
    return {status: 400, message: "Error saat upload Foto Profile !"};
  }
};

export const getProfilePicture = async (userId, loaderHandler) => {
  if (loaderHandler) loaderHandler(true);
  try {
    const storageRef = ref(storage, `users-picture/${userId}`);
    const result = await getDownloadURL(storageRef);
    if (loaderHandler) loaderHandler(false);
    return result;
  } catch (error) {
    if (loaderHandler) loaderHandler(false);
  }
};
