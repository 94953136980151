import React, {useState, createContext, useLayoutEffect} from "react";

// libs import
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {styled} from "@mui/material/styles";
import {
  Box,
  AppBar,
  Toolbar,
  Paper,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Avatar,
} from "@mui/material";
import {grey, blueGrey} from "@mui/material/colors";
import {
  Menu as MenuIcon,
  AccountCircle as UserIcon,
  LogoutRounded,
  SettingsRounded,
  ArrowBackIosRounded,
  MenuRounded,
} from "@mui/icons-material";
import {useNavigate, useLocation} from "react-router-dom";
import {auth, db} from "../Helper/Firebase";
import {getDoc, doc} from "firebase/firestore";
import {onAuthStateChanged} from "firebase/auth";

// components import
import Barlow from "../Components/Typography/Barlow";
import Menu from "../Components/Menu";
import {SidebarUser, SidebarAdmin} from "../Helper/SidebarList";
import LoadingState from "../Components/Loading/LoadingState";
import {logout} from "../Services/Auth";
import {getProfilePicture} from "../Services/Images";
import Logo from "../Assets/logo.png";
import Sidebar from "../Components/Sidebar";

const TransitionGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
  overflowX: "hidden",
  ...(open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  boxSizing: "border-box",
  ...(!open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

export const AuthContext = createContext();

const CoreLayout = ({children}) => {
  const [open, setOpen] = useState(true);
  const [showMenu, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const pathName = useLocation().pathname;
  const [reload, setReload] = useState(false);

  const loaderHandler = (bool) => setLoading(bool);
  const reloadPage = () => setReload((prev) => !prev);

  const MenuMD = [
    {
      label: "Profile",
      icon: <SettingsRounded />,
      action: () => navigate("/profile"),
    },
    {
      label: "Logout",
      action: () => logout(loaderHandler),
      icon: <LogoutRounded />,
    },
  ];

  const MenuXS = [
    {
      label: "Profile",
      icon: <SettingsRounded />,
      action: () => navigate("/profile"),
    },
    {
      label: "Logout",
      action: () => logout(loaderHandler),
      icon: <LogoutRounded />,
    },
    {
      label: "divider",
    },
    ...(currentUser?.role !== "admin"
      ? SidebarUser.map((item) => {
          return {
            label: item.label,
            icon: item.icon,
            action: () => navigate(item.url),
          };
        })
      : SidebarAdmin.map((item) => {
          return {
            label: item.label,
            icon: item.icon,
            action: () => navigate(item.url),
          };
        })),
  ];

  const getUser = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    await getDoc(docRef)
      .then(async (res) => {
        setShow(true);
        setCurrentUser(res.data());
        await getProfilePicture(res.data().userId, loaderHandler).then(
          (resProfile) => {
            setProfile(resProfile);
            // console.log(res.data().role)
            if (pathName === "/") {
              navigate(
                res.data()?.role === "admin" ? `/dashboard-admin` : "dashboard"
              );
            } else navigate(pathName);
          }
        );
      })
      .catch((err) => {
        alert(`error while getting user data,\n${err}`);
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    loaderHandler(true);
    onAuthStateChanged(auth, (user) => {
      if (user) getUser();
      if (!user) {
        setShow(false);
        navigate("/");
        setLoading(false);
      }
    });
  }, [auth.currentUser, reload]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        profile,
        setShow,
        loaderHandler,
        reloadPage,
      }}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {showMenu ? (
            <Grid container spacing={0} columns={30}>
              <TransitionGrid
                open={open}
                xs={0}
                md={open ? 6 : 2}
                lg={open ? 5 : 1}
              >
                <Box>
                  <List component={"nav"} sx={{marginTop: 1}}>
                    <ListItemButton onClick={() => setOpen(!open)}>
                      <ListItemIcon>
                        {!open ? (
                          <MenuIcon sx={{color: blueGrey[500]}} />
                        ) : (
                          <ArrowBackIosRounded sx={{color: blueGrey[500]}} />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </List>
                  <Divider />
                  <Sidebar
                    list={
                      currentUser?.role === "admin" ? SidebarAdmin : SidebarUser
                    }
                    expand={open}
                  />
                </Box>
              </TransitionGrid>
              <TransitionGrid
                open={open}
                xs={30}
                md={open ? 24 : 28}
                lg={open ? 25 : 29}
              >
                <Paper
                  elevation={0}
                  square
                  sx={{
                    maxHeight: "100vh",
                    minHeight: "100vh",
                    background: grey[200],
                    overflowY: "auto",
                  }}
                >
                  <AppBar position="sticky">
                    <Toolbar>
                      <img
                        src={Logo}
                        alt="E-Yang"
                        style={{
                          width: 30,
                          height: 30,
                          background: "white",
                          borderRadius: 999,
                          padding: 3,
                          marginRight: 8,
                        }}
                      />
                      <Barlow variant="h6" component="div" sx={{flexGrow: 1}}>
                        E-Yang
                      </Barlow>

                      {/* display for MD start */}

                      <Barlow
                        variant="h6"
                        component="div"
                        sx={{display: {xs: "none", md: "flex"}}}
                      >
                        {currentUser?.nama ? currentUser?.nama : ""}
                      </Barlow>
                      <Menu
                        isXsNone={true}
                        listMenu={MenuMD}
                        label={
                          profile ? (
                            <Avatar alt={currentUser?.nama} src={profile} />
                          ) : (
                            <UserIcon fontSize="large" sx={{color: "white"}} />
                          )
                        }
                      />

                      {/* display for MD end */}

                      {/* display for XS start */}

                      <Menu
                        isMdNone={true}
                        label={
                          <MenuRounded sx={{color: "white"}} fontSize="large" />
                        }
                        listMenu={MenuXS}
                      />

                      {/* display for XS end */}
                    </Toolbar>
                  </AppBar>
                  <Box component={"div"} sx={{padding: "30px 20px"}}>
                    {children}
                  </Box>
                </Paper>
              </TransitionGrid>
            </Grid>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </AuthContext.Provider>
  );
};

export default CoreLayout;
