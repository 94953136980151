import React from "react";
import {Box, styled} from "@mui/material";
import {blue} from "@mui/material/colors";
import Barlow from "../Typography/Barlow";

const CustomBox = styled(Box)({
  textAlign: "center",
  background: blue[500],
  padding: 5,
  borderRadius: 8,
});

const Title = ({label, variant}) => {
  return (
    <CustomBox>
      <Barlow color={"white"} variant={variant}>{label}</Barlow>
    </CustomBox>
  );
};

export default Title;
