import React, {useContext, useLayoutEffect, useState} from "react";

// libs import
import {Box, Button, Stack} from "@mui/material";

// components import
import StandardInput from "../../Components/MuiTextField/StandardInput";
import TextArea from "../../Components/MuiTextField/TextArea";
import {GeneralCard} from "../../Components/Card";
import Barlow from "../../Components/Typography/Barlow";
import {MoveToInboxRounded, SaveRounded} from "@mui/icons-material";
import {AuthContext} from "../../Layouts";
import {saveCatatan, getCatatan} from "../../Services/Catatan";
import LoadingComponent from "../../Components/Loading/LoadingComponent";
import {LoadingButton} from "@mui/lab";
import moment from "moment/moment";

// static variable
const labelInput = ["Judul", "Isi Catatan"];

const generateMessage = (index) => {
  return `${labelInput[index]} harus di isi !\n`;
};

const initialInput = {
  title: "",
  content: "",
};

const Catatan = ({userId, isHide}) => {
  const {currentUser} = useContext(AuthContext);
  const [data, setData] = useState(null);
  const [render, setRender] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [notesInput, setNotesInput] = useState(initialInput);

  const handleChange = (key, e) => {
    setNotesInput({
      ...notesInput,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const arrayError = [];
    Object.values(notesInput).map((val, index) => {
      if (!val) return arrayError.push(generateMessage(index));
    });

    if (arrayError.length > 0) return alert(arrayError.join(""));

    const payload = {
      title: notesInput.title,
      content: notesInput.content,
      date: new Date().getTime(),
      userId: currentUser.userId,
    };

    // console.log(notesInput.title);
    // console.log(notesInput.content);
    console.log(payload);
    await saveCatatan(setLoading, payload).then((res) => {
      if (res?.status === 400) return alert("error saat simpan catatan");

      setNotesInput(initialInput);
      setRender(!render);
    });
  };

  useLayoutEffect(() => {
    getCatatan(setLoading, userId ? userId : currentUser.userId).then((res) => {
      if (res?.status === 400) return alert("error saat pada data catatan");

      // console.log(res.docs);
      const arrayData =
        res.docs.map((item) => {
          return {
            ...item.data(),
            id: item.id,
          };
        }) || [];

      // console.log(arrayData);
      setData([...arrayData.sort((a, b) => b.date - a.date)]);
    });
  }, [render]);

  return (
    <>
      <Stack spacing={3}>
        <Barlow variant="h3" sx={{display: isHide ? "none" : "block"}}>
          Catatan
        </Barlow>
        <GeneralCard sx={{padding: 2, display: isHide ? "none" : "block"}}>
          <StandardInput
            label="Judul"
            placeholder="masukan judul untuk catatan"
            value={notesInput.title}
            onChange={(e) => handleChange("title", e)}
            sx={{paddingLeft: 2, paddingRight: 2}}
          />
        </GeneralCard>
        <GeneralCard sx={{padding: 2, display: isHide ? "none" : "block"}}>
          <TextArea
            placeholder="tulis catatanmu disini"
            value={notesInput.content}
            onChange={(e) => handleChange("content", e)}
          />
          <LoadingButton
            loadingPosition="end"
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<SaveRounded />}
            onClick={handleSubmit}
            loading={isLoading}
          >
            Simpan
          </LoadingButton>
        </GeneralCard>
        <Barlow variant="h3">Riwayat Catatan</Barlow>
        {isLoading ? (
          <GeneralCard sx={{height: 300}}>
            <LoadingComponent />
          </GeneralCard>
        ) : !data ? (
          <GeneralCard sx={{height: 300}}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                height: "100%",
              }}
            >
              <Barlow variant="h5" fontWeight={600}>
                Belum ada Catatan...
              </Barlow>
              <MoveToInboxRounded sx={{width: 200, height: 200}} />
            </Box>
          </GeneralCard>
        ) : (
          <Stack spacing={2}>
            {data.map((item) => (
              <GeneralCard key={item.id} sx={{padding: 3}}>
                <Stack spacing={1}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Barlow variant="h6">{item.title}</Barlow>
                    <Barlow variant="body2">
                      {moment(item.date).format("DD MMMM YYYY HH:mm")}
                    </Barlow>
                  </Box>
                  <Barlow>{item.content}</Barlow>
                </Stack>
              </GeneralCard>
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Catatan;
