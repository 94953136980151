import React, {useContext, useState} from "react";
import {AuthContext} from "../../../Layouts";

// libs import
import {Stack, Button} from "@mui/material";
import {SaveRounded} from "@mui/icons-material";
import {arrayUnion} from "firebase/firestore";

// components import
import MuiRadio from "../../../Components/RadioButton";
import Barlow from "../../../Components/Typography/Barlow";
import {PopupSuccess} from "../../../Components/Popup";
import LoadingComponent from "../../../Components/Loading/LoadingComponent";

// static variable
import {DukunganSosialInput} from "../../../Helper/DukunganSosialInput";
import {updateTaskProgress, setResultDukungan} from "../../../Services/Periksa";
import {
  radioErrorCheck,
  radioMessageTemplate,
} from "../../../Services/Functions";

const initialTask = {
  fungsiFisik: {
    adl: false,
    iadl: false,
    imt: false,
    resikoJatuh: false,
    statusKerentaan: false,
    nyeri: false,
  },
  fungsiKognitif: {
    tahap1: false,
    tahap2: false,
    resultTahap2: 0,
    tahap3: false,
  },
};

const DukunganSosial = ({nextTab}) => {
  const {currentUser} = useContext(AuthContext);
  const [radio, setRadio] = useState({
    ask1: 100,
    ask2: 100,
    ask3: 100,
    ask4: 100,
    ask5: 100,
  });
  const [isLoading, setLoading] = useState(false);

  const onChange = (key, e) => {
    setRadio({
      ...radio,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const error = radioErrorCheck(Object.values(radio));
    if (error.length > 0) return alert(radioMessageTemplate(error));

    const result = Object.values(radio).reduce(
      (a, b) => parseInt(a) + parseInt(b)
    );

    // const payloadResult = {
    //   resultDukungan: result,
    //   date: new Date().getTime(),
    //   userId: currentUser.userId,
    // };
    const payload = {
      resultDukungan: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };

    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      async (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress");

        if (result >= 7) return nextTab(0, "Tidak ada disfungsi keluarga");
        if (result >= 4) return nextTab(0, "Disfungsi keluarga sedang");
        return nextTab(0, "Disfungsi keluarga tinggi");
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Stack spacing={3}>
          <Barlow variant="h6" textAlign={"center"}>
            Pengkajian dukungan sosial, penilaian menggunakan APGAR test
          </Barlow>
          {Object.keys(DukunganSosialInput).map((item, index) => (
            <MuiRadio
              key={index}
              label={`${index + 1}. ${DukunganSosialInput[item].label}`}
              value={radio[item]}
              options={DukunganSosialInput[item].answers}
              onChange={(e) => onChange(item, e)}
            />
          ))}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            endIcon={<SaveRounded />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      )}
    </>
  );
};

export default DukunganSosial;
