import React from "react";
import {TextField, Box, MenuItem} from "@mui/material";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Barlow from "../Typography/Barlow";
import PropTypes from "prop-types";

const MuiTextField = ({type, SelectOptions, ...rest}) => {
  return (
    <>
      {type === "date" && (
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => <TextField {...rest} {...params} />}
              {...rest}
            />
          </LocalizationProvider>
        </Box>
      )}
      {type === "select" && (
        <TextField select {...rest}>
          {SelectOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {type !== "date" && type !== "select" && (
        <TextField margin="normal" type={type} required {...rest} />
      )}
    </>
  );
};

MuiTextField.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  margin: PropTypes.any,
  required: PropTypes.bool,
  name: PropTypes.string,
  autoComplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

// margin="normal"
// required
// fullWidth
// id="email"
// label="Email Address"
// name="email"
// autoComplete="email"
// autoFocus

export default MuiTextField;
