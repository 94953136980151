import React, {useReducer, useContext} from "react";
import {FungsiFisikContext} from "..";
import {AuthContext} from "../../../../Layouts";

// libs import
import FormControl from "@mui/material/FormControl";

// components import
import {AdlInput} from "../../../../Helper/AdlInput";
import MuiRadio from "../../../../Components/RadioButton";
import {Box, Button} from "@mui/material";
import {SaveRounded} from "@mui/icons-material";
import {updateTaskProgress} from "../../../../Services/Periksa";
import {arrayUnion} from "firebase/firestore";

// static const
import {
  radioErrorCheck,
  radioMessageTemplate,
} from "../../../../Services/Functions";
const reducer = (state, action) => {
  switch (action.type) {
    case "changeInput":
      return {...state, [action.key]: action.value};

    default:
      break;
  }
};

const ACTION = {
  changeInput: "changeInput",
};

// main Fucntion
const ADL = ({disable, nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadFisik, setLoading} = useContext(FungsiFisikContext);
  const [state, dispatch] = useReducer(reducer, {
    ask1: 100,
    ask2: 100,
    ask3: 100,
    ask4: 100,
    ask5: 100,
    ask6: 100,
    ask7: 100,
    ask8: 100,
    ask9: 100,
    ask10: 100,
  });

  const handleSubmit = async () => {
    const error = radioErrorCheck(Object.values(state));
    if (error.length > 0) return alert(radioMessageTemplate(error));

    // console.log(state);
    // console.log(payloadFisik);
    const arrResult =
      Object.values(state).map((item) => {
        return parseInt(item);
      }) || [];

    // console.log(arrResult);

    const result = arrResult.reduce((a, b) => a + b);

    // const payload = {...payloadFisik, adl: true, resultAdl: result};
    const payload = {
      fungsiFisik: {
        ...payloadFisik,
        adl: true,
      },
      resultAdl: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };
    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        let message;
        if (result <= 11) message = "Mengalami gangguan ADL";
        if (result > 11) message = "Tidak mengalami gangguan ADL";

        nextStep(message);
        reload();
      }
    );
  };

  return (
    <>
      <FormControl>
        {Object.keys(AdlInput).map((item, index) => (
          <Box key={index} marginTop={2}>
            <MuiRadio
              label={`${index + 1}. ${AdlInput[item].label}`}
              value={state[item]}
              onChange={(e) =>
                dispatch({
                  type: ACTION.changeInput,
                  key: item,
                  value: e.target.value,
                })
              }
              options={AdlInput[item].answer}
            />
          </Box>
        ))}
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        sx={{marginTop: 5}}
        endIcon={<SaveRounded />}
        onClick={handleSubmit}
        disabled={disable}
      >
        Submit
      </Button>
    </>
  );
};

export default ADL;
