import React, {useLayoutEffect, useContext, useState} from "react";
import {AuthContext} from "../../../../Layouts";
import {FungsiFisikContext} from "..";
import Cookies from "js-cookies";

// libs imports
import {InputAdornment, TextField, Button, Box} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {NavigateNextRounded, SaveRounded} from "@mui/icons-material";
import {arrayUnion} from "firebase/firestore";

// components imports
import {updateTaskProgress} from "../../../../Services/Periksa";
import {green} from "@mui/material/colors";

// static Const
const initialInput = {
  beratBadan: "",
  tinggiLutut: "",
  umur: "",
  tinggiBadan: "",
};

const getTinggiBadan = (gender, kneeHeight, age) => {
  // check null value
  if (!kneeHeight || !age) return 0;

  // male
  if (gender === "male")
    return Math.round(64.19 - 0.04 * age) + Math.round(2.02 * kneeHeight);

  // female
  return Math.round(84.88 - 0.24 * age) + Math.round(1.83 * kneeHeight);
};

const labelInput = ["berat badan", "tinggi lutut", "umur", "tinggi badan"];

// main function
const IMT = ({disable, nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadFisik, setLoading} = useContext(FungsiFisikContext);
  const [inputImt, setInput] = useState(initialInput);

  const handleChange = (key, e) => {
    setInput({...inputImt, [key]: e.target.value});
  };

  const handleSubmit = async () => {
    // checking value of payload item
    const errorArr = [];
    Object.values(inputImt).map((val, index) => {
      if (!val) errorArr.push(`${labelInput[index]} harus di isi !\n`);
    });

    // if error return alert
    if (errorArr.length > 0) return alert(errorArr.join(""));

    // math the result
    const resultImt = inputImt.beratBadan / (inputImt.tinggiBadan / 100);

    // initialize popup message
    let message;

    const result = Number(resultImt).toFixed(2);

    if (resultImt < 17.0) {
      console.log("Kurus Berat");
      message = `Hasil IMT: ${result} (Kurus Berat)`;
    } else if (resultImt >= 17.0 && resultImt <= 18.4) {
      console.log("Kurus Ringan");
      message = `Hasil IMT: ${result} (Kurus Ringan)`;
    } else if (resultImt > 18.4 && resultImt <= 25.0) {
      console.log("Normal");
      message = `Hasil IMT: ${result} (Normal)`;
    } else if (resultImt > 25.0 && resultImt <= 27.0) {
      console.log("Gemuk Ringan");
      message = `Hasil IMT: ${result} (Gemuk Ringan)`;
    } else {
      console.log("Gemuk Berat");
      message = `Hasil IMT: ${result} (Gemuk Ringan)`;
    }

    // setting up payload
    const payload = {
      fungsiFisik: {
        ...payloadFisik,
        imt: true,
      },
      resultImt: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };

    // check payload's value
    console.log(payload);

    // update progress Task in firebase
    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress");
        Cookies.setItem("resultImt", resultImt);
        Cookies.setItem("messageImt", message);
        nextStep(message);
        reload();
      }
    );
  };

  useLayoutEffect(() => {
    setInput({
      ...inputImt,
      tinggiBadan: getTinggiBadan(
        currentUser.jk,
        inputImt.tinggiLutut,
        inputImt.umur
      ),
    });
  }, [inputImt.tinggiLutut, inputImt.umur]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid md={6} xs={12}>
          {/* jenis kelamin */}
          <TextField
            label="Jenis Kelamin"
            margin="normal"
            fullWidth
            disabled
            value={currentUser.jk === "male" ? "Laki-Laki" : "Perempuan"}
          />

          {/* umur */}
          <TextField
            label="Umur"
            margin="normal"
            fullWidth
            value={inputImt.umur}
            onChange={(e) => handleChange("umur", e)}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Tahun</InputAdornment>
              ),
            }}
            disabled={disable}
          />

          {/* Tinggi Lutut */}
          <TextField
            label="Tinggi Lutut"
            margin="normal"
            fullWidth
            type="number"
            value={inputImt.tinggiLutut}
            onChange={(e) => handleChange("tinggiLutut", e)}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            disabled={disable}
          />
        </Grid>
        <Grid md={6} xs={12}>
          {/* Tinggi Badan */}
          <TextField
            label="Tinggi Badan"
            margin="normal"
            fullWidth
            type="number"
            disabled
            value={inputImt.tinggiBadan}
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
          />

          {/* Berat Badan */}
          <TextField
            label="Berat Badan"
            margin="normal"
            fullWidth
            type="number"
            value={inputImt.beratBadan}
            onChange={(e) => handleChange("beratBadan", e)}
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
            disabled={disable}
          />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="contained"
        sx={{marginTop: 5}}
        endIcon={<SaveRounded />}
        onClick={handleSubmit}
        disabled={disable}
      >
        Submit
      </Button>
    </>
  );
};

export default IMT;
