import React, { useContext, useState } from "react";
import { Form } from "antd";
import TandaVitalInput from "./common/TandaVitalInput";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Barlow from "../../../Components/Typography/Barlow";
import { Button } from "@mui/material";
import { updateTaskProgress } from "../../../Services/Periksa";
import { arrayUnion } from "firebase/firestore";
import { AuthContext } from "../../../Layouts";
import LoadingComponent from "../../../Components/Loading/LoadingComponent";
import { PopupSuccess, PopupFailed } from "../../../Components/Popup";

const TandaVital = () => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [labelSuccess, setLabelSuccess] = useState("");

  const handleSubmit = async (values) => {
    const payload = {
      resultTandaVital: arrayUnion({
        date: new Date().getTime(),
        sistolik: values?.tekananDarah1,
        diastolik: values?.tekananDarah2,
        nadi: values?.nadi,
        respirasi: values?.respirasi,
        suhu: values?.suhu,
      }),
    };

    let messageTD = "";
    let messageSuhu = "";

    if (values?.tekananDarah1 >= 140 && values?.tekananDarah2 >= 90) {
      messageTD = "Waspada Tekanan Darah Tinggi";
    }

    if (values?.suhu >= 37.5) messageSuhu = "Demam";

    if (messageTD || messageSuhu) {
      setLabelSuccess(
        <div style={{ textAlign: "center" }}>
          {messageTD}
          <br />
          <br />
          {messageSuhu}
        </div>
      );
    } else setLabelSuccess("");

    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      (res) => {
        if (res?.status === 400) return setFailed(true);

        setSuccess(true);
      }
    );
  };

  if (loading) {
    return (
      <div style={{ height: 450, width: "100%" }}>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <>
      <PopupSuccess
        label={labelSuccess ? labelSuccess : "Data berhasil di update!"}
        open={isSuccess}
        onClose={() => setSuccess(false)}
      />
      <PopupFailed
        label="Gagal update data"
        open={isFailed}
        onClose={() => setFailed(false)}
      />
      <div className="boxContainer">
        <Barlow variant="h6" textAlign="center" sx={{ marginBottom: 5 }}>
          Tanda Tanda Vital
        </Barlow>
        <Form
          onFinish={handleSubmit}
          scrollToFirstError
          style={{ paddingBottom: 40 }}
          initialValues={{
            tekananDarah1: "",
            tekananDarah2: "",
            nadi: "",
            respirasi: "",
            suhu: "",
          }}
        >
          <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              <TandaVitalInput.TekananDarah />
            </Grid>
            <Grid item md={6} xs={12}>
              <TandaVitalInput.Nadi />
            </Grid>
            <Grid item md={6} xs={12}>
              <TandaVitalInput.Respirasi />
            </Grid>
            <Grid item md={6} xs={12}>
              <TandaVitalInput.Suhu />
            </Grid>
          </Grid>
          <Button
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
            }}
            type="submit"
            variant="contained"
            fullWidth
          >
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
};

export default TandaVital;
