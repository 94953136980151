import React from "react";
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Sidebar = ({list, expand}) => {
  const navigate = useNavigate();
  return (
    <List component={"nav"} sx={{marginTop: 1}}>
      {list.map((sidebar, index) => (
        <ListItemButton key={index} onClick={() => navigate(sidebar.url)}>
          <ListItemIcon>{sidebar.icon}</ListItemIcon>
          <ListItemText primary={expand ? sidebar.label : <>&nbsp;</>} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default Sidebar;
