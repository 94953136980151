import React, {useContext, useState} from "react";
import {AuthContext} from "../../../../Layouts";
import {KognitifContext} from "..";

// libs import
import {Button, Stack} from "@mui/material";

// components import
import MuiRadio from "../../../../Components/RadioButton";

// static variable
import {Tahap3Input} from "../../../../Helper/Tahap3Input";
import {SaveRounded} from "@mui/icons-material";
import {
  updateTaskProgress,
  setResultKognitif,
} from "../../../../Services/Periksa";
import {arrayUnion} from "firebase/firestore";

const Tahap3 = ({nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadKognitif, loaderHandler} = useContext(KognitifContext);
  const [radio, setRadio] = useState(100);

  const handleChange = (e) => setRadio(parseInt(e.target.value));

  const handleSubmit = async () => {
    if (radio === 100) return alert("pilih salah satu kondisi!");

    const result = payloadKognitif.resultTahap2 + radio;

    const payload = {
      fungsiKognitif: {
        ...payloadKognitif,
        tahap3: false,
        tahap1: false,
        tahap2: false,
        resultTahap2: 0,
      },
      resultKognitif: arrayUnion({
        date: new Date().getTime(),
        result: result,
      }),
    };

    await updateTaskProgress(currentUser.userId, payload, loaderHandler).then(
      async (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        // console.log(radio);

        if (result >= 3) nextStep(0, "Fungsi kognitif Baik");
        if (result <= 2) nextStep(0, "Lansia mengalami gangguan kognitif");

        reload();
      }
    );
  };

  return (
    <>
      <Stack spacing={3} style={{marginTop: 50}}>
        {Object.keys(Tahap3Input).map((item, index) => (
          <MuiRadio
            value={radio}
            onChange={handleChange}
            label={Tahap3Input[item].label}
            options={Tahap3Input[item].answers}
            key={index}
          />
        ))}
        <Button
          style={{marginTop: 100}}
          variant="contained"
          color={"primary"}
          fullWidth
          endIcon={<SaveRounded />}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

export default Tahap3;
