import React, {useState, useContext, useEffect} from "react";

// libs import
import {Layout} from "antd";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {Stack, Button, Box} from "@mui/material";
import {
  ChangeCircleRounded,
  CancelRounded,
  ArchiveRounded,
} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import dayjs from "dayjs";

// components import
import {AuthContext} from "../../Layouts";
import Title from "../../Components/Title";
import UserInput from "../../Helper/UserInput";
import {GeneralCard} from "../../Components/Card";
import MuiTextField from "../../Components/MuiTextField";
import {handleChangeForm} from "../../Services/Functions";
import {updateUserData} from "../../Services/Auth";
import {uploadProfilePicture} from "../../Services/Images";
import LoadingComponent from "../../Components/Loading/LoadingComponent";
import {PopupSuccess, PopupFailed} from "../../Components/Popup";

const Profile = () => {
  const {Content} = Layout;
  const {
    listInput,
    typeInput,
    labelInput,
    placeholderInput,
    optionJK,
    initialValue,
  } = UserInput;
  const {profile, currentUser, reloadPage} = useContext(AuthContext);
  const [inputProfile, setInput] = useState(initialValue);

  const initialPicture = {
    file: profile,
    objUrl: "",
    fromAPI: 1,
  };

  const [imageUser, setImage] = useState(initialPicture);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFail, setFail] = useState(false);
  const [errorMessage, setError] = useState("");
  const [cancel, setCancel] = useState(false);

  const onChangeImage = (e) => {
    setImage({
      ...imageUser,
      fromAPI: 0,
      file: e.target.files[0],
      objUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  const reload = () => {
    setTimeout(() => {
      console.log("reload!");
      reloadPage();
    }, 2000);
  };

  const onSubmit = async () => {
    const payload = {
      ...inputProfile,
      tanggalLahir: dayjs(inputProfile.tanggalLahir) / 1000,
    };

    if (imageUser.fromAPI === 1) {
      await updateUserData(currentUser.userId, payload, setLoading).then(
        (res) => {
          console.log(res);
          setSuccess(true);
          reload();
        }
      );
    } else {
      setLoading(true);
      await uploadProfilePicture(currentUser.userId, imageUser.file).then(
        async (res) => {
          if (res.status === 400) {
            setFail(true);
            setError(res.message);
          } else {
            setImage({...imageUser, fromAPI: 0});
            await updateUserData(currentUser.userId, payload, setLoading).then(
              () => {
                setSuccess(true);
                reload();
              }
            );
          }
        }
      );
    }
  };

  // left input component
  const leftInput = Object.values(inputProfile).map((item, index) => {
    const lastIndex = Math.round(Object.values(inputProfile).length / 2);
    if (index < lastIndex)
      return (
        <MuiTextField
          key={index}
          label={labelInput[index]}
          value={item}
          onChange={(e) =>
            handleChangeForm(
              setInput,
              inputProfile,
              listInput[index],
              e,
              typeInput[index]
            )
          }
          type={typeInput[index]}
          SelectOptions={listInput[index] === "jk" && optionJK}
          required
          fullWidth
          margin="normal"
          placeholder={placeholderInput[index]}
          autoFocus={index === 0 ? true : false}
        />
      );
  });

  // right input component
  const rightInput = Object.values(inputProfile).map((item, index) => {
    const firstIndex = Math.round(Object.values(inputProfile).length / 2);
    if (index >= firstIndex)
      return (
        <MuiTextField
          key={index}
          label={labelInput[index]}
          value={item}
          onChange={(e) =>
            handleChangeForm(
              setInput,
              inputProfile,
              listInput[index],
              e,
              typeInput[index]
            )
          }
          type={typeInput[index]}
          SelectOptions={listInput[index] === "jk" && optionJK}
          required
          fullWidth
          margin="normal"
          placeholder={placeholderInput[index]}
          autoFocus={index === 0 ? true : false}
        />
      );
  });

  useEffect(() => {
    setInput({
      nama: currentUser?.nama,
      jk: currentUser?.jk,
      nik: currentUser?.nik,
      tanggalLahir: dayjs(currentUser?.tanggalLahir * 1000).format(
        "YYYY/MM/DD"
      ),
      alamat: currentUser?.alamat,
      noHP: currentUser?.noHP,
      pekerjaan: currentUser?.pekerjaan,
    });
  }, [currentUser, cancel]);

  return (
    <div>
      <PopupSuccess
        open={isSuccess}
        label="Berhasil mengubah data Profile !"
        onClose={() => setSuccess(false)}
      />
      <PopupFailed
        open={isFail}
        label={errorMessage}
        onClose={() => {
          setError("");
          setFail(false);
        }}
      />
      <GeneralCard elevation={1}>
        <Layout>
          {/* root title */}
          <Content>
            <Title variant={"h4"} label={"Profile"} />
          </Content>
          {/* grid */}
          <Content style={{marginTop: 20}}>
            <Grid container spacing={2}>
              {/* profile picture grid */}
              <Grid xs={12} md={4} sx={{minHeight: "500px"}}>
                {isLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    <Title variant={"h6"} label={"Profile Picture"} />
                    <Stack spacing={2} sx={{marginTop: 2, padding: 1}}>
                      {imageUser.fromAPI === 1 ? (
                        <img
                          src={imageUser.file}
                          style={{borderRadius: 8}}
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={imageUser.objUrl}
                          style={{borderRadius: 8}}
                          alt="profile"
                        />
                      )}
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{width: "100%"}}
                      >
                        <Button
                          endIcon={<ChangeCircleRounded />}
                          variant="contained"
                          color="primary"
                          size="large"
                          component="label"
                        >
                          Ganti Foto
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={onChangeImage}
                          />
                        </Button>
                        {!imageUser.fromAPI && (
                          <Button
                            endIcon={<CancelRounded />}
                            variant="contained"
                            color="error"
                            size="large"
                            onClick={() => setImage(initialPicture)}
                          >
                            Batal
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Grid>

              {/* user profile grid */}
              <Grid xs={12} md={8} sx={{minHeight: "500px"}}>
                {isLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    <Title variant={"h6"} label={"User Profile"} />
                    <Grid container sx={{padding: 1}}>
                      <Grid xs={12} md={6}>
                        {leftInput}
                      </Grid>
                      <Grid xs={12} md={6}>
                        {rightInput}
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: {xs: "column", sm: "row"},
                      }}
                      alignItems="center"
                      marginTop={1}
                      gap={2}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        endIcon={<ArchiveRounded />}
                        sx={{
                          background: green["500"],
                          "&:hover": {background: green["300"]},
                        }}
                        onClick={() => onSubmit()}
                      >
                        Simpan
                      </Button>
                      <Button
                        variant="contained"
                        endIcon={<CancelRounded />}
                        sx={{
                          background: red["500"],
                          "&:hover": {background: red["300"]},
                          width: {xs: "100%", md: "fit-content"},
                        }}
                        onClick={() => setCancel((prev) => !prev)}
                      >
                        Batal
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Content>
        </Layout>
      </GeneralCard>
    </div>
  );
};

export default Profile;
