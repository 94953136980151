export const KondisiPsikologisInput = {
  ask1: {
    label: (
      <>
        <b>Dalam satu minggu terakhir,</b> <br />
        Apakah Ibu/Bapak sebenarnya puas dengan kehidupanIbu/Bapak ?
      </>
    ),
    answers: [
      {
        label: "Ya",
        value: 0,
      },
      {
        label: "Tidak",
        value: 1,
      },
    ],
  },
  ask2: {
    label: (
      <>
        <b>Dalam satu minggu terakhir,</b> <br />
        Apakah Ibu/Bapak sering merasa bosan?
      </>
    ),
    answers: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  ask3: {
    label: (
      <>
        <b>Dalam satu minggu terakhir,</b> <br />
        Apakah Ibu/Bapak sering merasa tidak berdaya?
      </>
    ),
    answers: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  ask4: {
    label: (
      <>
        <b>Dalam satu minggu terakhir,</b> <br />
        Apakah Ibu/Bapak lebih senang tinggal di rumah, daripada pergi ke luar
        dan mengerjakan sesuatu hal yang baru?
      </>
    ),
    answers: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  ask5: {
    label: (
      <>
        <b>Dalam satu minggu terakhir,</b> <br />
        Apakah perasaan Ibu/Bapak merasa tidak berharga?
      </>
    ),
    answers: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
};
