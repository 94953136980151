import {
  DashboardRounded,
  MonitorHeartRounded,
  MenuBookRounded,
  PersonPinCircleRounded,
  InfoRounded,
} from "@mui/icons-material";

export const SidebarUser = [
  {
    label: "Dashboard",
    icon: <DashboardRounded />,
    url: "/dashboard",
  },
  {
    label: "Periksa",
    icon: <MonitorHeartRounded />,
    url: "/periksa",
  },
  {
    label: "Catatan",
    icon: <MenuBookRounded />,
    url: "/catatan",
  },
  {
    label: "Lokasi Lansia",
    icon: <PersonPinCircleRounded />,
    url: "/lokasi-lansia",
  },
  {
    label: "Info",
    icon: <InfoRounded />,
    url: "/info",
  },
];

export const SidebarAdmin = [
  {
    label: "Dashboard",
    icon: <DashboardRounded />,
    url: "/dashboard-admin",
  },
];
