import React from "react";
import {Tabs, Tab} from "@mui/material";

const MuiTabs = ({listTabs = [], value, onChange}) => {
  return (
    <Tabs
      value={value}
      orientation="horizontal"
      variant="scrollable"
      onChange={(e, newValue) => {
        onChange(newValue);
      }}
    >
      {listTabs.map((tab, index) => (
        <Tab
          key={index}
          icon={tab.icon}
          label={tab.label}
          iconPosition={tab.iconPosition}
        />
      ))}
    </Tabs>
  );
};

export default MuiTabs;
