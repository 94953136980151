import React from "react";
import {Stack, TextField} from "@mui/material";
import Barlow from "../../Typography/Barlow";

const StandardInput = ({label, ...res}) => {
  return (
    <Stack spacing={2}>
      <Barlow variant="h6">{label}</Barlow>
      <TextField variant="standard" InputLabelProps={{shrink: true}} {...res} />
    </Stack>
  );
};

export default StandardInput;
