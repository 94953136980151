import {db} from "../../Helper/Firebase";
import {
  addDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";

const docCatatan = collection(db, "catatan");

export const saveCatatan = async (loaderHandler, payload) => {
  loaderHandler(true);
  try {
    const result = await addDoc(docCatatan, payload);
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};

export const getCatatan = async (loaderHandler, userId) => {
  loaderHandler(true);
  try {
    const result = await getDocs(
      query(docCatatan, where("userId", "==", userId))
    );
    loaderHandler(false);
    return result;
  } catch (error) {
    loaderHandler(false);
    return {status: 400};
  }
};
