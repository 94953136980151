export const AdlInput = {
  ask1: {
    label: "Apakah lansia dapat mengendalikan pembuangan tinja?",
    answer: [
      {
        label: "Tak terkendali atau tak teratur (perlu pencahar)",
        value: 0,
      },
      {
        label: "Kadang-kadang tak terkendali (1x seminggu)",
        value: 1,
      },
      {
        label: "Terkendali teratur",
        value: 2,
      },
    ],
  },
  ask2: {
    label: "Apakah lansia dapat mengendalikan rangsang kemih?",
    answer: [
      {
        label: "Tak terkendali atau pakai kateter",
        value: 0,
      },
      {
        label: "Kadang-kadang tak terkendali (maks sekali per 24 jam)",
        value: 1,
      },
      {
        label: "Mandiri",
        value: 2,
      },
    ],
  },
  ask3: {
    label:
      "Apakah lansia dapat membersihkan diri (seka muka, sisir rambut, dll)?",
    answer: [
      {
        label: "Butuh pertolongan orang lain",
        value: 0,
      },
      {
        label: "Mandiri",
        value: 1,
      },
    ],
  },
  ask4: {
    label: "Apakah lansia dapat menggunakan jamban (masuk dan keluar)?",
    answer: [
      {
        label: "Tergantung pertolongan orang lain",
        value: 0,
      },
      {
        label:
          "Perlu pertolongan pada beberapa kegiatan tetapi dapat mengerjakan sendiri beberapa kegiatan yang lain",
        value: 1,
      },
      {
        label: "Mandiri",
        value: 2,
      },
    ],
  },
  ask5: {
    label: "Apakah lansia dapat makan sendiri?",
    answer: [
      {
        label: "Tidak mampu",
        value: 0,
      },
      {
        label: "Perlu ditolong memotong makanan",
        value: 1,
      },
      {
        label: "Mandiri (makanan disediakan dan bisa dijangkau)",
        value: 2,
      },
    ],
  },
  ask6: {
    label: "Apakah lansia dapat berubah sikap dari berbaring ke duduk?",
    answer: [
      {
        label: "Tidak mampu",
        value: 0,
      },
      {
        label: "Perlu banyak bantuan untuk bisa duduk (2orang)",
        value: 1,
      },
      {
        label: "Bantuan minimal 1 orang",
        value: 2,
      },
      {
        label: "Mandiri",
        value: 3,
      },
    ],
  },
  ask7: {
    label: "Apakah lansia dapat berpindah/berjalan?",
    answer: [
      {
        label: "Tidak mampu",
        value: 0,
      },
      {
        label: "Bisa (pindah) dengan kursi roda",
        value: 1,
      },
      {
        label: "Berjalan dengan bantuan 1 orang",
        value: 2,
      },
      {
        label: "Mandiri",
        value: 3,
      },
    ],
  },
  ask8: {
    label: "Apakah lansia dapat memakai baju?",
    answer: [
      {
        label: "Tergantung orang lain",
        value: 0,
      },
      {
        label: "Sebagian dibantu (misal mengancing baju)",
        value: 1,
      },
      {
        label: "Mandiri",
        value: 2,
      },
    ],
  },
  ask9: {
    label: "Apakah lansia dapat naik turun tangga?",
    answer: [
      {
        label: "Tidak mampu",
        value: 0,
      },
      {
        label: "Butuh pertolongan",
        value: 1,
      },
      {
        label: "Mandiri",
        value: 2,
      },
    ],
  },
  ask10: {
    label: "Apakah lansia dapat mandi?",
    answer: [
      {
        label: "Tergantung orang lain",
        value: 0,
      },
      {
        label: "Mandiri",
        value: 1,
      },
    ],
  },
};
