import React, {useState, useContext} from "react";
import {AuthContext} from "../../../../Layouts";
import {FungsiFisikContext} from "..";

// libs import
import {Button, Stack} from "@mui/material";

// components import
import MuiRadio from "../../../../Components/RadioButton";
import Barlow from "../../../../Components/Typography/Barlow";

// static const
import {StatusKerentaanInput} from "../../../../Helper/StatusKerentaanInput";
import {SaveRounded} from "@mui/icons-material";
import {updateTaskProgress, setResultFisik} from "../../../../Services/Periksa";
import {arrayUnion} from "firebase/firestore";

const optionStyle = {
  marginTop: 5,
};

const getMessage = (value) => {
  switch (value) {
    case 1:
      return "Sangat Sehat";
    case 2:
      return "Sehat";
    case 3:
      return "Cukup Sehat";
    case 4:
      return "Sedikit Rentan";
    case 5:
      return "Butuh Bantuan Ringan";
    case 6:
      return "Butuh Bantuan Sedang";
    case 7:
      return "Sangat Butuh Bantuan";
    case 8:
      return "Butuh Bantuan Sepenuhnya";
    case 9:
      return "Mendekati Akhir Hayat";

    default:
      break;
  }
};

const initialPayload = {
  adl: false,
  iadl: false,
  imt: false,
  resikoJatuh: false,
  statusKerentaan: false,
  nyeri: false,
};

// main function
const StatusKerentaan = ({disable, nextStep, reload}) => {
  const {currentUser} = useContext(AuthContext);
  const {payloadFisik, setLoading} = useContext(FungsiFisikContext);

  const [radio, setRadio] = useState(100);

  const onChange = (e) => setRadio(parseInt(e.target.value));

  const handleSubmit = async () => {
    if (radio === 100) return alert("Ups, pilih salah satu kondisi!");

    const payload = {
      fungsiFisik: {
        ...initialPayload,
      },
      resultKerentaan: arrayUnion({
        date: new Date().getTime(),
        result: radio,
      }),
    };

    await updateTaskProgress(currentUser.userId, payload, setLoading).then(
      async (res) => {
        if (res?.status === 400)
          return alert("error saat update task progress !");

        nextStep(getMessage(radio));
        setTimeout(() => {
          reload();
        }, 3000);
      }
    );
  };

  return (
    <>
      <Stack spacing={2}>
        <Barlow variant="h6" textAlign={"center"}>
          Status Kerentaan dinilai menggunakan Clinical Frailty Scale, Rockwood
          Version 2.0 (E.N)
        </Barlow>
        {Object.keys(StatusKerentaanInput).map((item, index) => (
          <MuiRadio
            key={index}
            label={StatusKerentaanInput[item].label}
            value={radio}
            onChange={onChange}
            options={StatusKerentaanInput[item].answers}
            optionStyle={optionStyle}
          />
        ))}
        <Button
          variant="contained"
          color="primary"
          endIcon={<SaveRounded />}
          onClick={handleSubmit}
          disabled={disable}
          fullWidth
        >
          Simpan
        </Button>
      </Stack>
    </>
  );
};

export default StatusKerentaan;
