import React from "react";
import {Modal, Fade, Box, Backdrop} from "@mui/material";
import {CheckCircleRounded, ErrorRounded} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  "&:focus": {
    outline: "none",
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 3,
  flexDirection: "column"
};

export const PopupSuccess = ({open, label, onClose, sx, ...rest}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={{...style, ...sx}} {...rest}>
          <CheckCircleRounded
            sx={{width: 200, height: 200, color: green["A400"]}}
          />
          {label}
        </Box>
      </Fade>
    </Modal>
  );
};

export const PopupFailed = ({open, label, onClose, sx, ...rest}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={{...style, ...sx}} {...rest}>
          <ErrorRounded
            sx={{width: 200, height: 200, color: red["600"]}}
          />
          {label}
        </Box>
      </Fade>
    </Modal>
  );
};
